import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import TrashIcon from "@/assets/images/trash-red-icon.svg";
import EditIcon from "@/assets/images/edit-blue-icon.svg";
import InputText from "../form-group/inputText";
import API from "../../services/ApiService";
import BlueCheckIcon from "@/assets/images/blue-check-icon.svg";
import RedCrossIcon from "@/assets/images/red-x-icon.svg";
import { ExclamationCircleFilled } from "@ant-design/icons";

import { ApiEndPoint } from "../../services/ApiEndpoint";
const { confirm } = Modal;
function CustomModel({ openCustomModal, setCustomModalOpen, modalData, selectedData, setSelectedData }) {
  const [show, setShow] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [changedData, setChangedData] = useState({});
  // const [selectedData, setSelectedData] = useState({});
  const [showEdit, setShowEdit] = useState(false);


  useEffect(() => {
    apiHit(modalData?.key);
  }, [modalData?.key, showEdit]);

  useEffect(() => {
    setShow(openCustomModal);
    if (!openCustomModal) {
      resetForm();
    }
  }, [openCustomModal]);

  // useEffect(() => {
  //   console.log(selectedData, "Updated selectedData");
  // }, [selectedData]);

  function resetForm() {
    setSelectedData({});
    setChangedData({})
    setShowEdit(false);
  }

  async function apiHit(term) {
    try {
      const response = await API.get(`${ApiEndPoint.common}?type=${term}&status=all`);
      setApiData(response?.data?.data);
    } catch (error) {
      // setShow(false)
      console.log(error, `${term} error`);
    }
  }

  function handleChange(e) {
    console.log(e,'eeeeee');
    
    let { name, value } = e.target

    if (name === "is_active") {
      value = +value
    }

    if (name === "active") {
      value = +value
    }
    setSelectedData((prev) => ({
      ...prev,
      [name]: value
    }))
    setChangedData((prev) => ({
      ...prev,
      [name]: value
    }))



  }
  const clearData = () =>{
    resetForm()
    const newSelectedData = modalData.fields.reduce((acc, key) => {
      if (key !== 'id') { 
        if(key=='active' || key=='isActive'){
          acc[key]=null
        }else{
          acc[key] = ''; 
        }  
      }
      return acc;
  }, {});
    setSelectedData(newSelectedData)}
  function resetSelectedData() {
    // const newSelectedData = Object.keys(selectedData).reduce((acc, key) => {
    //   acc[key] = '';
    //   return acc;
    // }, {});

    // setSelectedData(newSelectedData);
  }
  // console.log(selectedData, "vicky")
  async function handleAddUpdateSubmit() {
    try {

      if (selectedData?.id) {
        const response = await API.put(`${ApiEndPoint.common}?type=${modalData?.key}&id=${selectedData?.id}`, changedData);
        console.log(response, "reeeees")
      } else {
        const response = await API.post(`${ApiEndPoint.common}?type=${modalData?.key}`, selectedData);
      }
      apiHit(modalData?.key);
      resetForm();

      setShowEdit(false)
      clearData()
    } catch (error) {
      console.log(error, "api error");
    }
  }



  async function deleteData(id) {
    try {
      const response = await API.delete(`${ApiEndPoint.common}?type=${modalData?.key}&id=${id}`);
      apiHit(modalData?.key);
      resetForm();

    } catch (error) {
      console.log(error, "api error");
    }
  }

  function handleDelete(id) {
    confirm({
      title: "Are you sure you want to delete?",
      icon: <ExclamationCircleFilled />,
      // content: 'Some descriptions',
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteData(id);

      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }
  return (
    <>
      <Modal
        className="manage-option-list-model"
        width={600}
        title="Manage Inventory"
        centered
        open={show}
        onOk={() => {
          resetSelectedData()
          setShowEdit(true)

          // setShow(false);
          // setCustomModalOpen(false);
        }}
        onCancel={() => {
          setShow(false);
          resetSelectedData()

          // setSelectedData({})
          // setChangedData({})
          setCustomModalOpen(false);
        }}
        okText="Add New Inventory"
        footer={(_, { OkBtn }) => <OkBtn />}
      >
        <div className="table-wrapper">
          <table>
            <thead>
              <tr>
                {modalData?.fields?.map((item) => (
                  item == "id" ? "" :
                    <th key={item}>{item?.endsWith("DESC") ? "Description" : item?.endsWith("CD") || item?.endsWith("ID") ? "Original Code" : item}</th>
                ))}
                {/* <th>Description</th>
                <th>ACTIVE (1)</th>
                <th>oRIGINAL coDE</th> */}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {apiData?.map((item) => (
                <tr>

                  {modalData?.fields?.map((field, j) => (
                    field !== 'id' ?
                      field == 'is_active' || field == 'active' ?
                        <td>
                          <img
                            src={
                              Number(item[field]) ? BlueCheckIcon : RedCrossIcon
                            } />
                        </td> :
                        <td>{item[field]}</td> : ""
                  )
                  )}
                  {/* <td>{item?.description}</td> */}
                  {/* <td>
                    <img
                      src={
                        Number(item?.is_active) ? BlueCheckIcon : RedCrossIcon
                      }
                    />
                  </td> */}
                  {/* <td>--</td> */}

                  <td>
                    <div className="action-btn">
                      <img
                        className="edit-icon"
                        onClick={() => {
                          setSelectedData(item);
                          setShowEdit(true);
                        }}
                        src={EditIcon}
                        alt=""
                      />
                      {
                        item.category !== 'INSTALLATION' &&
                        <img className="trash-icon" src={TrashIcon}
                          onClick={() => handleDelete(item?.id)}
                          alt="" />
                      }
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal>

      <Modal
        className="manage-option-list-model"
        width={400}
        title={selectedData?.id ? "Update Data" : "Add New"}
        centered
        open={showEdit}
        onOk={()=>{handleAddUpdateSubmit()
          setShowEdit(false);}

        }
        onCancel={() => { setShowEdit(false);
           clearData()
           }}
        okText={selectedData?.id ? "Update Data" : "Add New"}
        footer={(_, { OkBtn }) => <OkBtn />}
      >
        <div className="row">
          <div className="col-12">

            {
              modalData?.fields?.map((item) => (
                item === 'id' ? "" :
                  item == 'is_active' ?
                    <div className="col-12 mt-3">
                      <label htmlFor="" className="label">
                        Active
                      </label>
                      <div className="inputs d-flex align-items-center gap-3">
                        <div className="input d-flex align-items-center gap-2">
                          <input
                            type="radio"
                            name="is_active"
                            id="true"
                            value={1}
                            defaultChecked={!selectedData?.id || !selectedData.is_active ? null : Number(selectedData.is_active) ? true : false}
                            onChange={handleChange}

                          />{" "}
                          <label htmlFor="true" className="label mb-0">
                            True
                          </label>
                        </div>
                        <div className="input d-flex align-items-center gap-2">
                          <input
                            type="radio"
                            name="is_active"
                            id="false"
                            value={0}
                            onChange={handleChange}
                            defaultChecked={!selectedData?.id || !selectedData.is_active ? null : Number(selectedData.is_active) ? false : true} />
                          <label htmlFor="false" className="label mb-0">
                            False
                          </label>
                        </div>
                      </div>
                    </div>
                    :
                    item == 'active' ?
                      <div className="col-12 mt-3">
                        <label htmlFor="" className="label">
                          Active
                        </label>
                        <div className="inputs d-flex align-items-center gap-3">
                          <div className="input d-flex align-items-center gap-2">
                            <input
                              type="radio"
                              name="active"
                              id="true"
                              value={1}
                              defaultChecked={!selectedData?.id || !selectedData.active ? null : Number(selectedData.active) ? true : false}
                              onChange={handleChange}

                            />{" "}
                            <label htmlFor="true" className="label mb-0">
                              True
                            </label>
                          </div>
                          <div className="input d-flex align-items-center gap-2">
                            <input
                              type="radio"
                              name="active"
                              id="false"
                              value={0}
                              onChange={handleChange}
                              defaultChecked={!selectedData?.id || !selectedData.active? null : Number(selectedData.active) ? false : true} />
                            <label htmlFor="false" className="label mb-0">
                              False
                            </label>
                          </div>
                        </div>
                      </div>

                      :

                      <>
                        {console.log(selectedData[item], "vvvl")}
                        <InputText
                          label={item}
                          name={item}
                          // defaultValue={selectedData[item]}
                          onChange={handleChange}
                          value={
                            selectedData[item]
                          }
                        />
                      </>

              ))
            }
          </div>
          {/* <div className="col-12 mt-3">
            <InputText label="oRIGINAL coDE" />
          </div> */}

        </div>
      </Modal>
    </>
  );
}

export default CustomModel;
