import { createSlice } from "@reduxjs/toolkit";
import { ApiEndPoint } from "../../services/ApiEndpoint";
import API from "../../services/ApiService";
import { useDispatch } from "react-redux";
import { openNotificationWithIcon } from "../../services/toast";
import assessmentSlice from "./assessmentSlice";

const initialState = {
  attachments: [],
  currentPage: 1,
  attachmentDetails: {},
  totalAttachments: 0,
  currentPage: 1,
  isLoading: false,
  errors: {},
};

const attachmentSlice = createSlice({
  name: "attachment",
  initialState,
  reducers: {
    getAttachments(state, action) {
      state.attachments = action.payload.attachmentData;
      state.totalAttachments = action.payload.totalAttachments;
      state.isLoading = false;
    },
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state, action) {
      state.isLoading = false;
    },
    changePage(state, action) {
      state.currentPage = action.payload;
      state.isLoading = false;
    },
    addAttachmentDetails(state, action) {
      const key = Object.keys(action.payload)[0];
      const value = action.payload[key];
      state.attachmentDetails[key] = value;
    },

    setErrors: {
      prepare(obj, msg) {
        return {
          payload: {
            obj,
            msg,
          },
        };
      },
      reducer(state, action) {
        if (action.payload.msg === "fullObj") {
          state.errors = action.payload.obj;
        } else {
          const key = Object.keys(action.payload.obj)[0];
          const value = action.payload.obj[key];
          state.errors[key] = value;
        }
      },
    },
  },
});

export const {
  addAttachmentDetails,
  changePage,
  //   setBuildingId,
  //   addBuildingDetails,
  //   openBuildingResourceModal,
  //   closeBuildingResourceModal,
  //   setErrors,
  //   defaultBuilding,
  //   setBuildingsNull,
} = attachmentSlice.actions;

export function getAttachmentData(id, item) {
  return async function (dispatch, getState) {
    dispatch({ type: "attachment/startLoading" });
    try {
      const attatchment = getState()?.attachment;
      const currentPage = attatchment?.currentPage;

      const response = await API.get(
        `${ApiEndPoint.attachments}/${id}?item=${item}&skip=${(currentPage - 1) * 10}&limit=10`
      );
      console.log(response?.data?.data, "resss");
      const attachmentData = response?.data?.data?.attachments;
      const totalAttachments = response?.data?.data?.total;
      dispatch({
        type: "attachment/getAttachments",
        payload: { attachmentData, totalAttachments },
      });
    } catch (error) {
      dispatch({ type: "attachment/stopLoading" });
      openNotificationWithIcon("error", error?.response?.data?.message);
    }
  };
}
export function addAttachment(attachment) {
  return async function (dispatch, getState) {
    dispatch({ type: "attachment/startLoading" });
    try {
      const response = await API.post(ApiEndPoint.attachment, attachment);
      openNotificationWithIcon("success", "attachment created successfully");
      //   dispatch({ type: "attachment/addattachment" });
      // dispatch({type:"attachment/defaultattachment"})
      return response;
    } catch (error) {
      openNotificationWithIcon("error", error?.response?.data?.message);
    } finally {
      dispatch({ type: "attachment/stopLoading" });
    }
  };
}

export function getSingleAttachment(id) {
  return async function (dispatch, getState) {
    dispatch({ type: "attachment/startLoading" });
    try {
      const response = await API.get(`${ApiEndPoint.attachment}/${id}`);
      console.log(response?.data?.data, "sa");
      return response?.data?.data;
    } catch (error) {
      openNotificationWithIcon("error", error?.response?.data?.message);
    } finally {
      dispatch({ type: "attachment/stopLoading" });
    }
  };
}
export function updateAttachment(id, updatedData) {
  return async function (dispatch, getState) {
    dispatch({ type: "attachment/startLoading" });
    try {
      const response = await API.put(
        `${ApiEndPoint.attachment}/${id}`,
        updatedData
      );

      return response;
    } catch (error) {
      openNotificationWithIcon("error", error?.response?.data?.message);
    } finally {
      dispatch({ type: "attachment/stopLoading" });
    }
  };
}
export default attachmentSlice.reducer;
