import React, { useEffect } from "react";
import BuildingsTable from "../../../../components/tables/BuildingTable";
import { changePage, getBuildingsAsb, getBuildingsLead } from "../../../../redux/slice/buildingSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

function BuildingWithoutAsbestos() {
  const location = useLocation()
  const pathName = location?.pathname?.split('-').at(-1)
  const category = pathName ? pathName.charAt(0).toUpperCase() + pathName.slice(1) : pathName
  const dispatch = useDispatch()
  const { currentPage,pageSize } = useSelector((state) => state.building)

  const getBuildingsFunc = async () => {
    if (pathName === 'lead') {
      dispatch(getBuildingsLead(pathName,0))
    } else {
      dispatch(getBuildingsAsb(pathName,0))
    }
  }
  useEffect(() => {
    return () => {
      dispatch(changePage({page:1,pageSize}));
    };
  }, []);


  useEffect(() => {
    getBuildingsFunc()
  }, [currentPage,pageSize])

  return (
    <BuildingsTable category={pathName} asbPresent={0} leadPage={pathName === 'lead' ? 1 : 0} buildingType={`Buildings without ${category}`} />
  );
}

export default BuildingWithoutAsbestos;
