import loginBanner from "@/assets/images/login-img.jpg";
import buildingImg from "@/assets/images/building.png";
import MaskIcon from "@/assets/images/main-layout-bg.svg";
import LoginContent from "./login";
import ResetPassword from "./resetPassword";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalState } from "../../redux/slice/globalStates";
import OtpContent from "./otpContent";
function Login() {
  const dispatch = useDispatch();
  const { showOTPmodalOpen, showForgotPassword, showVerifyEmail } = useSelector(
    (state) => state.globalStates
  );
  const closeOtpModal = () =>
    dispatch(
      setGlobalState({ showOTPmodalOpen: false, showVerifyEmail: false })
    );
  return (
    <>
      <section className="vh-100 login-page">
        <img className="bg-img-login" src={MaskIcon} alt="" />
        <div className="container-fluid">
          <div className="row">
            <div className=" px-0 d-none d-sm-block" style={{ width: "60%" }}>
              <img src={loginBanner} alt="Login" className="w-100 vh-100" />
            </div>
            <div
              className="d-flex align-items-center login-input-details"
              style={{ width: "40%" }}
            >
              <div className="d-flex align-items-center h-custom-2 login-form">
                {!showForgotPassword ? <LoginContent /> : <ResetPassword />}
                <Modal
                  open={showOTPmodalOpen}
                  className="otp-modal"
                  onCancel={closeOtpModal}
                  footer={null}
                >
                  <OtpContent showVerifyEmail={showVerifyEmail} />
                </Modal>
              </div>
              <img className="building-img" src={buildingImg} alt="building" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Login;
