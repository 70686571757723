import React, { useEffect, useState } from "react";
import "./asbestos-attachments.scss";
import {
  GoBack,
  HeaderText,
  MainLayout,
  SearchInput,
  ExcelDownloadBtn,
  PdfDownloadBtn,
  InputText,
} from "@/components";
import { Modal, Select, DatePicker, Upload, message, Pagination, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { addAttachment, addAttachmentDetails, changePage, getAttachmentData, getSingleAttachment, updateAttachment } from "../../../redux/slice/attachmentSlice";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { openNotificationWithIcon } from "../../../services/toast";
import dayjs from "dayjs";
import { config } from "../../../config/config";

function AsbestosAttachments() {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search);
  const buildingId = searchParams.get("id");
  const { attachments, attachmentDetails, isLoading, totalAttachments, currentPage } = useSelector((state) => state.attachment)
  const [modalOpen, setModalOpen] = useState(false);
  const [updateObj, setUpdateObj] = useState({})
  const [attachmentId, setAttachmentId] = useState(null)
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [fileList, setFileList] = useState([])
  const [attachmentObj, setAttachmentObj] = useState({})
  const building = location?.state?.building
  const message = location?.state?.message
  const pathName = location?.pathname?.substring(1)
  const parts = pathName.split('/');
  const item = parts[1].split('-')[0];

  const itemTitle = item?.charAt(0).toUpperCase() + item?.slice(1)


  const uploadProps = {
    onRemove: (file) => {

      // setFileList((curr) => curr.filter((e) => e.uid !== file.uid));
      setFileList([]);
      // setTempImage(currentUser?.profile_picture ?? UserAvatar)
    },
    beforeUpload: (file) => {

      const isPdf = file.type === 'application/pdf';
      if (!isPdf) {
        openNotificationWithIcon('error', 'You can only upload PDF files!');
        return false; // Reject the file if it's not a PDF
      }
      // if (!file.type.startsWith('image/')) {
      // openNotificationWithIcon("error", "You can only upload image files!");
      //   return false;
      // }
      // setTempImage(URL.createObjectURL(file))
      // setFileList((curr) => [...curr, file]);
      setFileList([file]);
      console.log([file], "file")
      if (attachmentId) {
        setUpdateObj((prev) => ({ ...prev, FILENAME: true }))
      }

      return false;
    },
    fileList,
  };
  const handleOk = async () => {
    try {


      setConfirmLoading(true);

      if(!attachmentId){

        if (!fileList.length) {
          
          return openNotificationWithIcon('error', 'File is required')
        } else if (!attachmentDetails.ASB_FOUND && item !== 'rec' && item !== 'ADVF') {
          return openNotificationWithIcon('error', 'Verification is required')
        } else if (!attachmentDetails.UPLOAD_DATE) {
          return openNotificationWithIcon('error', 'Upload Date is required')
        }
      }

      const formData = new FormData();

      if (fileList.length > 0) {
        formData.append("pdf", fileList[0]);
        // }
      }

      if (attachmentDetails.UPLOAD_DATE) {
        formData.append('UPLOAD_DATE', attachmentDetails.UPLOAD_DATE);
      }

      if (attachmentDetails.ASB_FOUND) {
        formData.append("ASB_FOUND", attachmentDetails.ASB_FOUND);
      }

      formData.append("FILE_TYPE", item.toLowerCase());


      formData.append("BUILDING_ID", buildingId);


      if (attachmentId) {
        let formData = new FormData()
        if (updateObj.ASB_FOUND) {
          formData.append("ASB_FOUND", attachmentDetails.ASB_FOUND)
        }
        if (updateObj.UPLOAD_DATE) {
          formData.append("UPLOAD_DATE", attachmentDetails.UPLOAD_DATE)
        }
        if (updateObj.FILENAME) {
          formData.append("pdf", fileList[0])
        }
        await dispatch(updateAttachment(attachmentId, formData))
      } else {
        await dispatch(addAttachment(formData))
      }

      await dispatch(getAttachmentData(buildingId, item))
      setModalOpen(false)
      setFileList([])
      setAttachmentId(null)
      setAttachmentObj({})
    } catch (error) {
      console.log(error, "error")
    } finally {
      setConfirmLoading(false);
    }

  };
  const handleChange = (event, date) => {
    let obj;
    if (date || date == '') {
      console.log(date, "date")
      obj = { UPLOAD_DATE: date }
      dispatch(addAttachmentDetails(obj))
    } else {
      const { name, value } = event.target
      obj = { [name]: value }
      dispatch(addAttachmentDetails(obj))
    }
    setAttachmentObj((prev) => ({ ...prev, ...obj }))
    setUpdateObj((prev) => ({ ...prev, ...obj }))
  };

  const handlePageChange = (page) => {
    dispatch(changePage(page))
  }


  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAttachmentData(buildingId, item))
  }, [currentPage])


  async function singleAttachment(id) {
    // console.log(id, "id")
    setAttachmentId(id)
    let data = await dispatch(getSingleAttachment(id))
    
    
    const file = {
      uid: Date.now(),
      name: data?.name,
      status: 'done',
      url: `${config.AWS_URL}${data?.name}`,
    };
    console.log(data, "data")
    setAttachmentObj((prev) => ({
      ...prev,
      FILENAME: data?.name,
      ASB_FOUND: Number(data?.additional_data),
      BUILDING_ID: data?.BUILDING_ID,
      UPLOAD_DATE: data?.date_added,

    }))
    setFileList([file]);
    setModalOpen(true)
  }


  return (
    <MainLayout>
      <div className="asbestos-attachments-wrapper">
        <HeaderText>
          <GoBack />
          {itemTitle} Attachments · <span className="sample-title">FJLC {building}</span>
        </HeaderText>
        <div className="table-wrapper">
          <div className="table-outer-header">
            <div className="header-filters">
              <SearchInput
                width={400}
                disabled={true}
                icon="start"
                placeholder="Search by file name, ID....."
                className="user-search-input"
              />
              <Select
                disabled={true}
                style={{ width: 170 }}
                placeholder="Material Condition"
                suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                // onChange={}
                options={[
                  { value: "jack", label: "Jack" },
                  { value: "lucy", label: "Lucy" },
                ]}
              />
            </div>
            <div className="header-buttons">
              {/* <ExcelDownloadBtn />
              <PdfDownloadBtn /> */}
              <button
                onClick={() => setModalOpen(true)}
                className="button yellow-button"
              >
                + Upload {itemTitle}
              </button>
            </div>
          </div>
          <div className="table-overflow-wrapper container p-0" id="customScrollTable">
            <table>
              <thead>
                <tr>
                  {[
                    "File NAME",
                    (item !== 'ADVF' && item !== 'rec') ? "ADDITIONAL_DATA" : null,
                    (item !== 'ADVF' && item !== 'rec') ? `${item} DETECTED? ` : null,
                    "Date of report",
                    "date uploaded",
                    "Installation ID",
                  ].map((i) => (<>
                    {i &&
                      <th key={i}>{i}</th>}

                  </>

                  ))}
                </tr>
              </thead>
              <tbody>
                {
                  isLoading &&
                  <Spin fullscreen size="large" />
                }
                {attachments?.map((data) => (
                  <tr key={data?.file_id} onClick={() => singleAttachment(data?.file_id)} className="cursor-pointer">
                    {/* <td>{data?.FILENAME}</td> */}
                    <td>{data?.name?.split('/')?.pop()?.split('.')[0]}</td>
                    {
                      item !== 'ADVF' && item !== 'rec' &&
                      <>
                        <td>{data?.additional_data}</td>
                        <td>{Number(data?.additional_data) ? `${itemTitle} detected` : `No ${itemTitle} detected`}</td>
                      </>
                    }
                    <td>{(new Date(data?.date_of_report)).toISOString().split('T')[0]}</td>
                    <td>{(new Date(data?.date_added)).toISOString().split('T')[0]}</td>
                    <td>FJLC</td>
                  </tr>
                ))}
                {
                  !isLoading && attachments?.length > 0 && totalAttachments > 10 &&
                  < div className="pagination">
                    <Pagination defaultCurrent={1} defaultPageSize={10} current={currentPage} total={totalAttachments} onChange={handlePageChange} />
                  </div>
                }
              </tbody>
            </table>
          </div>
        </div>
        <Modal
          width={480}
          className="attach-asbestos-modal"
          title=""
          centered
          open={modalOpen}
          onOk={handleOk}
          confirmLoading={confirmLoading}
          onCancel={
            () => {
              setModalOpen(false)
              setFileList([])
              setAttachmentId(null)
              setAttachmentObj({})
            }}
          okText={!attachmentId ? `Upload ${itemTitle} File` : `Update ${itemTitle} File`}
          footer={(_, { OkBtn }) => (
            <>
              <OkBtn />
            </>
          )}
        >
          <div className="attach-asbestos-file">
            <div className="title">
              <h3>Attach {itemTitle} File</h3>
              <p className="">FJLC {building}</p>
            </div>
            <div className="date-picker">
              <label htmlFor="" className="label">
                DATE OF {item} REPORT
              </label>
              <DatePicker onChange={handleChange} 
              // value={attachmentObj?.UPLOAD_DATE ? moment(attachmentObj?.UPLOAD_DATE) : null}
              value={
                attachmentObj?.UPLOAD_DATE ? dayjs(attachmentObj?.UPLOAD_DATE, 'YYYY-MM-DD') : null
              }
              format={'YYYY-MM-DD'}
              suffixIcon={false} />
            </div>
            <div className="upload-file">
              <label htmlFor="" className="label">
                UPLOAD FILE
              </label>
              {/* <Upload {...props}>
                <button>Choose File</button>
              </Upload> */}
              <Upload
                maxCount={1}
                {...uploadProps}

              >
                <button icon={null}>Choose File</button>

              </Upload>
              <small className="warning-text">
                Please review the reports you are about to upload and attach.
              </small>
            </div>
            {item !== 'rec' && item !== 'ADVF' &&
              <div className="verification-wrapper">
                <label htmlFor="" className="label">
                  Verification
                </label>
                <div className="radio-buttons">
                  <div className="radio-btn">
                    <input
                      type="radio"
                      name="ASB_FOUND"
                      id="verification_yes"
                      // defaultChecked={
                      //   !attachmentId
                      //     ? null
                      //     : Number(attachmentObj?.ASB_FOUND)
                      //       ? true
                      //       : false
                      // }
                      checked={attachmentObj.ASB_FOUND == 1}
                      onChange={handleChange}
                      value={1}
                    />
                    <label htmlFor="verification_yes">Yes</label>
                  </div>
                  <div className="radio-btn">

                    <input
                      type="radio"
                      name="ASB_FOUND"
                      id="verification_no"
                      // defaultChecked={
                      //   !attachmentId
                      //     ? null
                      //     : Number(attachmentObj?.ASB_FOUND)
                      //       ? false
                      //       : true
                      // }
                      checked={attachmentObj.ASB_FOUND == 0}

                      onChange={handleChange}
                      value={0}
                    />
                    <label htmlFor="verification_no">No</label>
                  </div>
                </div>
                <p className="">
                  {message}
                  {/* By selecting Yes, you are certifying that you reviewed the data
                and validate that Asbestos was detected in the reports */}
                </p>
              </div>
            }
          </div>
        </Modal>
      </div>
    </MainLayout >
  );
}

export default AsbestosAttachments;
