import React, { useEffect, useState } from "react";
import {
    CheckIcon, CrossIcon, ExcelDownloadBtn,
    PdfDownloadBtn, MainLayout,
    GoBack, HeaderText, SearchInput
} from "@/components";
import { Empty, Pagination, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import BuildingModal from "../modals/BuildingModal";
import { changePage, getBuildings, getBuildingsAsb, getBuildingsAsbRecord, getBuildingsLead, getBuildingsLeadRecord, getBuildingsRecord, openBuildingResourceModal } from "../../redux/slice/buildingSlice";
import { Link, useLocation } from "react-router-dom";
import { handleExcelDownload, handlePdfDownload } from "../../services/exportHandlers";
import { checkAccess, downloadFile } from "../../services/Utils";
import { updateAccesstoState } from "../../redux/slice/userSlice";

const headers = [
    ["Building ID", "Building Name", "Full Survey", "Demolished", "Certified",
        "Friable", "Description"]
];

function BuildingsTable({ asbPresent, buildingType, leadPage, category }) {
    const { search: locationSearch } = useLocation()
    const { currentUser, hasAddAccess } = useSelector((state) => state.user)
    const { buildings, isLoading, totalBuildings, buildingsRecord,pageSize } = useSelector((state) => state.building)
    const [buildingId, setBuildingId] = useState("")
    const [search, setSearch] = useState("")
    const dispatch = useDispatch()
    const [getAll, setGetAll] = useState(locationSearch ? true : false)



    const handlePageChange = (page,pageSize) => {
        dispatch(changePage({page,pageSize}))
    }

    const openModal = (id) => {
        setBuildingId(id);
        dispatch(openBuildingResourceModal(id));
    };

    const handleSearch = () => {
        if (asbPresent !== "unknown") {
            if (leadPage) {
                dispatch(getBuildingsLead(category, asbPresent, search))
            } else {
                dispatch(getBuildingsAsb(category, asbPresent, search))
            }
        } else {
            dispatch(getBuildings(category, 'certified', 0, search))
        }
    }

    const handleClearSearch = () => {
        setSearch("");
        // handleSearch();
    };
    useEffect(() => {
        const verifyAccess = async () => {
            const addAccess = await checkAccess(currentUser, 'buildingAdd');
            dispatch(updateAccesstoState({ hasAddAccess: addAccess }));

        };

        verifyAccess();
    }, [currentUser]);

    function createBuildingData(buildingsRecord) {
        return buildingsRecord?.map(building => ([
            building.BUILDING_ID,
            building.BUILDING_NAME,
            building.FULL_SURVEY ? "Yes" : "No",
            building.DEMOLISHED ? "Yes" : "No",
            building.CERTIFIED ? "Yes" : "No",
            building.FRIABLE ? "Yes" : "No",
            building.DESCRIPTION,
        ]));
    }


    async function handleExcel() {

        let buildingsRecord;
        if (asbPresent === "unknown") {
            buildingsRecord = await dispatch(getBuildingsRecord('certified'))
        } else {
            if (leadPage) {
                buildingsRecord = await dispatch(getBuildingsLeadRecord(asbPresent))
            } else {
                buildingsRecord = await dispatch(getBuildingsAsbRecord(asbPresent))
            }
        }
        const dataWithHeaders = [headers, ...createBuildingData()];
        handleExcelDownload(dataWithHeaders, "Buildings");
    }

    async function handlePdf() {
        let buildingsRecord;
        if (asbPresent === "unknown") {
            buildingsRecord = await dispatch(getBuildingsRecord('certified'))
        } else {
            if (leadPage) {
                buildingsRecord = await dispatch(getBuildingsLeadRecord(asbPresent))
            } else {
                buildingsRecord = await dispatch(getBuildingsAsbRecord(asbPresent))
            }
        }
        const data = await createBuildingData(buildingsRecord);
        handlePdfDownload(headers, data, "Buildings");
    }
    async function handleFile(file) {
        let type = file === 'xlsx' ? 'excel' : 'pdf'
        let url
        if (asbPresent === "unknown") {
            url = `v1/buildings/record?type=certified&format=${type}&category=${category}`
        } else {
            if (leadPage) {
                url = `v1/buildingsLead/record?format=${file}&ASB=${asbPresent}&category=${category}`
            } else {
                url = `v1/buildingsAsb/record?format=${file}&ASB=${asbPresent}&category=${category}`
            }
        }
        downloadFile(file, url, 'Buildings')
    }



    useEffect(() => {
        if (search === "") {
            if (asbPresent !== "unknown") {
                if (leadPage) {
                    dispatch(getBuildingsLead(category, asbPresent))
                } else {
                    dispatch(getBuildingsAsb(category, asbPresent))
                }
                // dispatch(getBuildingsAsb(asbPresent, search));
            } else {
                dispatch(getBuildings(category, 'certified', 0, search))
            }
        }
    }, [search]);

    // if(isLoading){
    //     return <Spin delay={20} fullscreen size="large" />
    // }

    return (
        <>
            <MainLayout buildingBgImage={true}>

                <div className="all-buildings-wrapper certified-building">
                    <HeaderText>
                        <GoBack />
                        {buildingType} · {totalBuildings}
                    </HeaderText>
                    <div className="table-wrapper">
                        <div className="table-outer-header">
                            <div className="header-filters">
                                <SearchInput
                                    width={300}
                                    icon="end"
                                    placeholder="Search by building name"
                                    className="user-search-input"
                                    onChange={(e) => setSearch(e?.target?.value)}
                                    onClear={handleClearSearch}
                                    onClick={handleSearch}
                                />
                            </div>
                            <div className="header-buttons">
                                <ExcelDownloadBtn onClick={() => handleFile('xlsx')} />
                                <PdfDownloadBtn onClick={() => handleFile('pdf')} />
                                {
                                    hasAddAccess &&

                                    <Link
                                        to={`/${leadPage || asbPresent === 'unknown' ? 'lead' : 'asbestos'}/buildings/create`}
                                        className="button yellow-button"
                                    >
                                        + Add New Building
                                    </Link>
                                }
                            </div>
                        </div>
                        <div className="table-overflow-wrapper container p-0" id="customScrollTable">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Building</th>
                                        <th style={{ width: "150px" }}>Building Name</th>
                                        <th>Full Survey</th>
                                        <th>Demolished</th>
                                        <th>Certified</th>
                                        <th>Friable</th>
                                        <th>Description</th>
                                        {
                                            getAll ?
                                                <>
                                                    <th>STRUCTURE NAME</th>
                                                    <th>YEAR CONSTRUCTED</th>
                                                    <th>ADDRESS</th>
                                                    <th>CITY</th>
                                                    <th>STATE</th>
                                                    <th>ZIP CODE</th>
                                                    <th>BUILDING SIZE</th>
                                                    <th>LEVELS</th>
                                                </>
                                                : null
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoading ? (
                                        <tr>
                                            
                                            {/* <td colSpan="7"> */}
                                                <Spin delay={2000} fullscreen size="large" />
                                            {/* </td> */}
                                        </tr>
                                    ) : buildings?.length > 0 ? (
                                        buildings?.map(building => (
                                            <tr key={building?.BUILDING_ID}>
                                                <td>{building?.BUILDING_ID}</td>
                                                <td className="link" onClick={() => openModal(building?.BUILDING_ID)}>
                                                    {building?.BUILDING_NAME}
                                                </td>
                                                <td>
                                                    {building?.has_full_survey ? <CheckIcon /> : <CrossIcon />}
                                                </td>
                                                <td>
                                                    {Number(building?.DEMOLISHED) ? <CheckIcon /> : <CrossIcon />}
                                                </td>
                                                <td>
                                                    {Number(building?.CERTIFIED) ? <CheckIcon /> : <CrossIcon />}
                                                </td>
                                                <td>
                                                    {Number(building?.has_friable_damage) ? <CheckIcon /> : <CrossIcon />}
                                                </td>
                                                <td>{building?.DESCRIPTION}</td>
                                                {
                                                    getAll ?
                                                        <>
                                                            <td>{building?.STRUCTURE_NAME}</td>
                                                            <td>{building?.YEAR_CONST}</td>
                                                            <td>{building?.ADDRESS}</td>
                                                            <td>{building?.CITY}</td>
                                                            <td>{building?.STATE}</td>
                                                            <td>{building?.ZIP}</td>
                                                            <td>{building?.BUILDING_SIZE}</td>
                                                            <td>{building?.NO_LEVELS}</td>
                                                        </>
                                                        : null
                                                }
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="7">
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <BuildingModal buildingId={buildingId} />
                    {!isLoading && buildings?.length > 0 && totalBuildings > 10 &&
                        <div className="pagination">
                            <Pagination defaultCurrent={1} total={totalBuildings} pageSize={pageSize} onChange={handlePageChange} />
                        </div>
                    }
                </div>
            </MainLayout>
        </>
    );
}

export default BuildingsTable;
