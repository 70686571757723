import React, { useEffect, useRef, useState } from "react";
import { HeaderText, GoBack, MainLayout, InputText } from "@/components";
import SampleCard from "./subComponent/sampleCard";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { DatePicker, Select, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  addSample,
  addSampleDetails,
  defaultSample,
  deleteSample,
  getCommon,
  getSampleData,
  setErrors,
  updateSample,
} from "../../../redux/slice/sampleSlice";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { openNotificationWithIcon } from "../../../services/toast";
import { checkEmpty, getFirstPathSegment, sampleValidationObj } from "../../../services/Utils";
import { check } from "prettier";
import dayjs from "dayjs";
const { confirm } = Modal;

function SaveAsbestosSample() {
  const dispatch = useDispatch();
  const deleteReasonRef = useRef("");
  const location = useLocation();
  const pathName = getFirstPathSegment(location?.pathname)
  const params = useParams();
  const Navigate = useNavigate();
  const {
    sampleDetails,
    errors,
    laboratory,
    ASB_HM_MATERIAL,
    material_condition,
    exposed_visible,
    damage_potential,
    hazzard_assessment,
    functional_space,
    sample_suspect_type,
    equipment_type,
    air_move_activity,
    access_difficulty,
    material_type,
    construction_type,
  } = useSelector((state) => state.sample);
  const [changedData, setChangedData] = useState({});
  const searchParams = new URLSearchParams(location.search);
  const buildingId = searchParams.get('id');
  const [sampleId, setSampleId] = useState(null)
  // const [materialMeasure, setMaterialMeasure] = useState({ a: null, b: null, c: null })

  const building = location?.state?.building
  // const handleMeasureUpdate = (e, part) => {
  //   const { name, value } = e.target;

  //   setMaterialMeasure((prev) => ({
  //     ...prev,
  //     [part]: value
  //   }))

  // }

  // useEffect(() => {
  //   const newObj = { MTRL_MSRMNT: materialMeasure };
  //   if (newObj) {
  //     console.log("newObj", newObj)
  //     dispatch(addSampleDetails(newObj));
  //     setChangedData((prev) => ({
  //       ...prev,
  //       MTRL_MSRMNT: JSON.stringify(materialMeasure)
  //     }))
  //   } else {
  //     console.log("newObj", newObj)

  //   }

  // }, [materialMeasure]);

  function getCommonData() {
    dispatch(getCommon("laboratory"));
    dispatch(getCommon("ASB_HM_MATERIAL"));
    dispatch(getCommon("exposed_visible"));
    dispatch(getCommon("functional_space"));
    dispatch(getCommon("material_condition"));
    dispatch(getCommon("equipment_type"));
    dispatch(getCommon("construction_type"));
    dispatch(getCommon("access_difficulty"));
    dispatch(getCommon("damage_potential"));
    dispatch(getCommon("hazzard_assessment"));
    dispatch(getCommon("sample_suspect_type"));
    dispatch(getCommon("material_type"));
    dispatch(getCommon("air_move_activity"));
  }

  function handleDelete() {
    confirm({
      title: 'Are you sure you want to delete this Sample?',
      icon: <ExclamationCircleFilled />,
      content: (
        <div>
          <p>Please provide a reason for deletion:</p>
          <InputText onChange={(e) => deleteReasonRef.current = e?.target?.value} />
        </div>
      ),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        if (deleteReasonRef?.current === '') {
          openNotificationWithIcon("error", 'Kindly provide reason for deletion');
        } else {
          dispatch(deleteSample(sampleId, deleteReasonRef?.current, buildingId))
          deleteReasonRef.current = ""
          Navigate(`/${pathName}/samples?id=${buildingId}`, {
            state: { building },
          });
        }
      },
      onCancel() {
        deleteReasonRef.current = ""
        console.log('Cancel');
      },
    });
  }


  // function deleteSampleFunc() {
  //   dispatch(deleteSample(sampleId, buildingId));
  //   Navigate(`/asbestos/samples?id=${buildingId}`, {
  //     state: { building },
  //   });
  // }
  useEffect(() => {
    getCommonData();
    if (params.id) {
      setSampleId(Number(params.id))
      getSampleDataFunc(Number(params.id))
    //   // setMaterialMeasure(sampleDetails?.MTRL_MSRMNT)
    }
    dispatch(defaultSample())
    const obj = { BUILDING_ID: buildingId + "" }
    dispatch(addSampleDetails(obj))
    return (() => {
      dispatch(setErrors({}, "fullObj"))
    })
  }, [])


  function getSampleDataFunc(id) {
    dispatch(getSampleData(id));
  }
  // const handleMeasure = (e, part) => {


  //   const { name, value } = e.target;

  //   setMaterialMeasure((prev) => ({
  //     ...prev,
  //     [part]: value
  //   }))

  // };


  const handleChange = (e, date, check) => {
    let obj;
    if (date || date == "") {
      console.log(date, "date")
      dispatch(addSampleDetails({ SAMPLE_DATE: date }))
      setChangedData((prev) => ({
        ...prev,
        SAMPLE_DATE: date,
      }));

      dispatch(setErrors({ SAMPLE_DATE: null }, "..."))
    }

    else if (check) {
      obj = { [check]: e }
      dispatch(addSampleDetails(obj))
      setChangedData((prev) => ({
        ...prev,
        [check]: e,
      }));
      dispatch(setErrors({ [check]: null }, "..."));
    } else {
      const { name, value } = e.target;

      if (name === "HAZ_ASSES_CD" || name === "VISIBLE_DIST" || name === "WATER_DAMAGE" || name === "PHY_DAMAGE" || name === "FRIABLE" || name === "DET_DAMAGE" || name === "ACCESS_DIST" || name === "ACTIVITY" || name === 'GEN_OCCUPANCY' || name === 'EXPOSED' || name === 'ACM_ACCESS_DIF_CD' || name === 'PIPE_DIA_0_2' || name === 'PIPE_DIA_3_6' || name === 'PIPE_DIA_7_12' || name === 'PIPE_DIA_12_PLUS' || name === 'PIPE_LINEAR_FT' || name === 'ELBOW') {
        obj = { [name]: Number(value) }
        setChangedData((prev) => ({
          ...prev,
          [name]: Number(value),
        }));
      } else {
        obj = { [name]: value };
        setChangedData((prev) => ({
          ...prev,
          [name]: value,
        }));
      }

      dispatch(addSampleDetails(obj));

      dispatch(setErrors({ [name]: null }, "..."));
    }
  };

  function validateData(sampleDetails) {
    let errors = {};
    let firstErrorField = null;
    for (const key in sampleDetails) {
      // if ((key === 'PIPE_DIA_0_2' || key === 'PIPE_DIA_3_6' || key === 'PIPE_DIA_7_12' || key === 'PIPE_DIA_12_PLUS' || key === 'PIPE_LINEAR_FT' || key === 'FITTINGS' || key === 'PIPE_COMMENTS' || key === 'ELBOW') && sampleDetails.ASB_TYPE !== 'PIPE') {
      //   //Do nothing
      // }
      if (
        sampleDetails[key] === null ||
        sampleDetails[key] === undefined ||
        sampleDetails[key] === ""
      ) {
        if (key === 'SAMPLE_ID' || key === 'SAMPLE_DATE' || key === 'LBTRY_ID' || key === 'COMMENTS') {

          errors[key] = `${sampleValidationObj[key]} is required`;
          firstErrorField = key;
        }
      }
    }
    dispatch(setErrors(errors, "fullObj"));
    // return Object.keys(errors).length === 0 ? null : "Validation Error";
    console.log(firstErrorField, "firstErrorFieldfirstErrorFieldfirstErrorField")
    return firstErrorField;
  }

  const handleSubmit = () => {
    const validate = validateData(sampleDetails);
    if (validate) {
      let elementsByName = document.getElementsByName(validate);
      if (elementsByName && elementsByName.length > 0) {
        const firstElement = elementsByName[0];
        firstElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      return;
    } else {
      try {
        if (!sampleId) {
          // const newObj = { MTRL_MSRMNT: materialMeasure }
          // dispatch(addSampleDetails(newObj));
          // dispatch(addSampleDetails({ BUILDING_ID: buildingId }))
          dispatch(addSample())
        } else {
          dispatch(updateSample(sampleId, changedData));
        }
        Navigate(`/${pathName}/samples?id=${buildingId}`, {
          state: { building },
        });
      } catch (error) {
        openNotificationWithIcon("error", error);
        console.log(error);
      }
    }
  }


  return (
    <MainLayout>
      <div className="sample-create-wrapper">
        <HeaderText>
          <GoBack />
          {!sampleId ? "Add New Sample" : "Update Sample"}·{" "}
          <span className="sample-title">{building}</span>
        </HeaderText>

        <div className="create-sample-form-wrapper">
          <SampleCard header="General">
            <div className="row">
              <div className="col-4">
                <InputText label="SAMPLE NAME" name="SAMPLE_ID" onChange={handleChange} errors={errors.SAMPLE_ID}
                  value={sampleDetails.SAMPLE_ID}
                />
              </div>
              <div className="col-4">
                <div style={{ paddingLeft: "7px", paddingTop: "1px" }}>
                  <label className="label" htmlFor="">
                    SAMPLE DATE
                  </label>
                  <DatePicker
                    size="large"
                    // disabledDate={disabledDate}
                    onChange={handleChange}
                    value={
                      sampleDetails?.SAMPLE_DATE ? dayjs(sampleDetails?.SAMPLE_DATE, 'YYYY-MM-DD') : null
                    }
                    format={'YYYY-MM-DD'}
                  />
                  {errors.SAMPLE_DATE && (
                    <small className="error-message">
                      {errors.SAMPLE_DATE}
                    </small>
                  )}
                </div>
              </div>
              <div className="col-4">
                <label className="label" htmlFor="">
                  LABORATORY
                </label>
                <Select
                  suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                  options={laboratory.map((lab) => ({
                    value: lab.LBTRY_NAME + "",
                    label: lab.LBTRY_NAME,
                  }))}
                  fieldNames={{ name: "LBTRY_ID" }}
                  onChange={(e) => handleChange(e, null, "LBTRY_ID")}
                  value={sampleDetails.LBTRY_ID}
                />
                {errors.LBTRY_ID && (
                  <small className="error-message">{errors.LBTRY_ID}</small>
                )}
              </div>
              <div className="col-12 mt-3">
                <label className="label" htmlFor="">
                  COMMENTS{" "}
                </label>
                <textarea
                  name="COMMENTS"
                  id=""
                  rows="3"
                  onChange={handleChange}
                  value={sampleDetails.COMMENTS}
                ></textarea>
              </div>
              {errors.COMMENTS && (
                <small className="error-message">{errors.COMMENTS}</small>
              )}
            </div>
          </SampleCard>
          <SampleCard header="Suspect Data">
            <div className="row">
              <div className="col-4">
                <InputText
                  label="Area ID (A or Pip1-TSIEQ)"
                  errors={errors.AREA_ID}
                  name="AREA_ID"
                  onChange={handleChange}
                  value={sampleDetails.AREA_ID}
                />
              </div>
              <div className="col-4">
                <label className="label" htmlFor="">
                  suspect type
                </label>
                <Select
                  suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                  options={[
                    { value: "HGA", label: "HGA" },
                    { value: "PIPE", label: "PIPE" },
                  ]}

                  // options={sample_suspect_type.map((item) => (
                  //   {
                  //     value: (item?.id + ""), label: item?.description
                  //   }))}
                  fieldNames={{ name: 'ASB_TYPE' }}
                  onChange={(e) => handleChange(e, null, "ASB_TYPE")} value={sampleDetails.ASB_TYPE}
                />
                {errors.ASB_TYPE && (
                  <small className="error-message">{errors.ASB_TYPE}</small>
                )}
              </div>

              <div className="col-4">
                <InputText
                  label="FLOOR"
                  name="FLOOR_ID"
                  onChange={handleChange}
                  value={sampleDetails.FLOOR_ID}
                  errors={errors.FLOOR_ID}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-4">
                <InputText
                  label="ROOM NUMBER (OPTIONAL)"
                  name="RM_ID"
                  onChange={handleChange}
                  value={sampleDetails.RM_ID}
                  errors={errors.RM_ID}
                />
              </div>
              <div className="col-4">
                <label className="label" htmlFor="">
                  FUNCTIONAL SPACE (OPTIONAL)
                </label>
                <Select
                  suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                  options={functional_space.map((space) => ({
                    value: space?.description + "",
                    label: space?.description,
                  }))}
                  fieldNames={{ name: 'FUNCTIONAL_SPACE_CD' }}
                  onChange={(e) => handleChange(e, null, "FUNCTIONAL_SPACE_CD")} value={sampleDetails?.FUNCTIONAL_SPACE_CD}
                />
                {errors.FUNCTIONAL_SPACE_CD && (
                  <small className="error-message">{errors.FUNCTIONAL_SPACE_CD}</small>
                )}
              </div>
              <div className="col-4">
                <InputText label="GENERAL OCCUPANCY (OPTIONAL)" type="number" name="GEN_OCCUPANCY" onChange={handleChange}
                  value={sampleDetails.GEN_OCCUPANCY} errors={errors.GEN_OCCUPANCY} />

              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <label className="label" htmlFor="">
                  SUSPECT DESCRIPTION / GENERAL COMMENTS
                </label>
                <textarea
                  id=""
                  rows="3"
                  name="SUSPECT_DSCRPTN"
                  onChange={handleChange}
                  value={sampleDetails?.SUSPECT_DSCRPTN}
                ></textarea>
              </div>
              {errors.SUSPECT_DSCRPTN && (
                <small className="error-message">
                  {errors.SUSPECT_DSCRPTN}
                </small>
              )}
            </div>
            <div className="row mt-3">
              <div className="col-4">
                <label className="label" htmlFor="">
                  MATERIAL TYPE
                </label>
                <Select
                  suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                  // onChange={}
                  options={
                    material_type.map((material) => (
                      { value: (material?.description + ""), label: material?.description }
                    ))

                  }
                  fieldNames={{ name: 'ACM_TYPE_CD' }}
                  onChange={(e) => handleChange(e, null, "ACM_TYPE_CD")} value={sampleDetails.ACM_TYPE_CD}
                />
                {errors.ACM_TYPE_CD && (
                  <small className="error-message">{errors.ACM_TYPE_CD}</small>
                )}
              </div>
              <div className="col-4">
                <label className="label" htmlFor="">
                  MATERIAL CONDITION
                </label>
                <Select
                  suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                  options={material_condition.map((material) => ({
                    value: material?.description + "",
                    label: material?.description,
                  }))}
                  fieldNames={{ name: 'MATL_COND_CD' }}
                  onChange={(e) => handleChange(e, null, "MATL_COND_CD")} value={sampleDetails?.MATL_COND_CD}
                />
                {errors.MATL_COND_CD && (
                  <small className="error-message">{errors.MATL_COND_CD}</small>
                )}
              </div>
              <div className="col-4">
                <label className="label" htmlFor="">
                  EXPOSED & VISIBLE %
                </label>
                <Select
                  suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                  // options={[
                  //   { value: "25", label: "< 25 %" },
                  //   { value: "50", label: "> 50 %" },
                  //   { value: "75", label: "> 75 %" },
                  //   { value: "100", label: "100 %" }
                  // ]}
                  options={exposed_visible.map((space) => ({
                    value: space?.description,
                    label: space?.description,
                  }))}
                  fieldNames={{ name: 'EXPOSED' }}
                  onChange={(e) => handleChange(e, null, "EXPOSED")} value={sampleDetails.EXPOSED}
                />
                {errors.EXPOSED && (
                  <small className="error-message">{errors.EXPOSED}</small>
                )}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-4">
                <label className="label" htmlFor="">
                  EQUIPMENT TYPE
                </label>
                <Select
                  suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                  // onChange={}
                  options={
                    equipment_type.map((equipment) => ({
                      value: equipment?.description + "",
                      label: equipment?.description
                    }))
                  }
                  fieldNames={{ name: 'EQUIP_TYPE_CD' }}
                  onChange={(e) => handleChange(e, null, "EQUIP_TYPE_CD")} value={sampleDetails.EQUIP_TYPE_CD}

                />
                {errors.EQUIP_TYPE_CD && (
                  <small className="error-message">{errors.EQUIP_TYPE_CD}</small>
                )}
              </div>
              <div className="col-4">
                <label className="label" htmlFor="">
                  CONSTRUCTION TYPE
                </label>
                <Select
                  suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                  // onChange={}
                  options={
                    construction_type.map((construction) => ({
                      value: construction?.description + "",
                      label: construction.CONST_TYPE_DESC
                    }))
                  }
                  fieldNames={{ name: 'CONST_TYPE_CD' }}
                  onChange={(e) => handleChange(e, null, "CONST_TYPE_CD")} value={sampleDetails.CONST_TYPE_CD}

                />
                {errors.CONST_TYPE_CD && (
                  <small className="error-message">{errors.CONST_TYPE_CD}</small>
                )}
              </div>
              <div className="col-4">
                <label className="label" htmlFor="">
                  MATERIAL MEASUREMENT (Fill out one or more)
                </label>
                <div className="row">
                  <div className="col-4">
                    <InputText name="MATL_SQ_FT" type='number' onChange={handleChange}
                      value={sampleDetails?.MATL_SQ_FT} errors={errors.MATL_SQ_FT} />
                    <label className="label" htmlFor="">
                      Square feet
                    </label>
                  </div>
                  <div className="col-4">
                    <InputText name="MATL_CUB_YD" type='number' onChange={handleChange}
                      value={sampleDetails?.MATL_CUB_YD} errors={errors.MATL_CUB_YD} />
                    <label className="label" htmlFor="">
                      Cubic Yard
                    </label>
                  </div>
                  <div className="col-4">
                    <InputText name="MATL_LN_FT" type='number' onChange={handleChange}
                      value={sampleDetails?.MATL_LN_FT} errors={errors.MATL_LN_FT} />
                    <label className="label" htmlFor="">
                      Linear Feet
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-4">
                <label className="label" htmlFor="">
                  ACCESS DIFFICULTY / OBSTRUCTIONS
                </label>
                <Select
                  suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                  options={
                    access_difficulty?.map((access) => ({
                      value: access?.description,
                      label: access?.description
                    }))
                  }
                  fieldNames={{ name: 'ACM_ACCESS_DIF_CD' }}
                  onChange={(e) => handleChange(e, null, "ACM_ACCESS_DIF_CD")} value={sampleDetails.ACM_ACCESS_DIF_CD}

                />
                {errors.ACM_ACCESS_DIF_CD && (
                  <small className="error-message">{errors.ACM_ACCESS_DIF_CD}</small>
                )}
              </div>
              <div className="col-4">
                <label className="label" htmlFor="">
                  AREA REMOVED / ABATED
                </label>
                <Select
                  suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                  // onChange={}
                  options={[
                    { value: 'FALSE', label: "Removed" },
                    { value: 'TRUE', label: "Abated" }
                  ]}

                  fieldNames={{ name: 'AREA_ABATED' }}
                  onChange={(e) => handleChange(e, null, "AREA_ABATED")} value={sampleDetails.AREA_ABATED}

                />
                {errors.AREA_ABATED && (
                  <small className="error-message">{errors.AREA_ABATED}</small>
                )}
              </div>
            </div>
          </SampleCard>
          <SampleCard header="Damage" cardBodyStyle="damage-card">
            <div className="row">
              <div className="col-4 card-body">
                <div className="card-title">Cause Of Damage</div>
                <div className="body-content">
                  <label htmlFor="" className="label">
                    PHYSICAL DAMAGE
                  </label>
                  <div className="radio-inputs">
                    <div className="radio-input">
                      <input
                        type="radio"
                        name="PHY_DAMAGE"
                        id="PHY_DAMAGE-yes"
                        value={1}
                        defaultChecked={!sampleId || checkEmpty(sampleDetails.PHY_DAMAGE) ? null : Number(sampleDetails.PHY_DAMAGE) === 1 ? true : false}
                        onChange={handleChange}
                      />

                      <label htmlFor="PHY_DAMAGE-yes">Yes</label>
                    </div>
                    <div className="radio-input">
                      <input
                        type="radio"
                        name="PHY_DAMAGE"
                        id="PHY_DAMAGE-no"
                        value={0}
                        defaultChecked={!sampleId || checkEmpty(sampleDetails.PHY_DAMAGE) ? null : Number(sampleDetails.PHY_DAMAGE) === 0 ? true : false}
                        onChange={handleChange}
                      />
                      <label htmlFor="PHY_DAMAGE-no">No</label>
                    </div>
                  </div>
                  {errors.PHY_DAMAGE && (
                    <small className="error-message">{errors.PHY_DAMAGE}</small>
                  )}
                  <label htmlFor="" className="label mt-3">
                    WATER DAMAGE
                  </label>
                  <div className="radio-inputs">
                    <div className="radio-input">
                      <input
                        type="radio"
                        name="WATER_DAMAGE"
                        id="WATER_DAMAGE-yes"
                        value={1}
                        defaultChecked={!sampleId || checkEmpty(sampleDetails.WATER_DAMAGE) ? null : Number(sampleDetails.WATER_DAMAGE) === 1 ? true : false}
                        // defaultChecked={false}
                        onChange={handleChange}
                      />
                      <label htmlFor="WATER_DAMAGE-yes">Yes</label>
                    </div>
                    <div className="radio-input">
                      <input
                        type="radio"
                        name="WATER_DAMAGE"
                        id="WATER_DAMAGE-no"
                        value={0}
                        defaultChecked={!sampleId || checkEmpty(sampleDetails.WATER_DAMAGE) ? null : Number(sampleDetails.WATER_DAMAGE) === 0 ? true : false} onChange={handleChange}
                      />
                      <label htmlFor="WATER_DAMAGE-no">No</label>
                    </div>
                  </div>
                  {errors.WATER_DAMAGE && (
                    <small className="error-message">
                      {errors.WATER_DAMAGE}
                    </small>
                  )}
                  <label htmlFor="" className="label mt-3">
                    Deterioration
                  </label>
                  <div className="radio-inputs">
                    <div className="radio-input">
                      <input
                        type="radio"
                        name="DET_DAMAGE"
                        id="DET_DAMAGE-yes"
                        value={1}
                        defaultChecked={!sampleId || checkEmpty(sampleDetails.DET_DAMAGE) ? null : Number(sampleDetails.DET_DAMAGE) === 1 ? true : false}
                        onChange={handleChange}
                      />
                      <label htmlFor="DET_DAMAGE-yes">Yes</label>
                    </div>
                    <div className="radio-input">
                      <input
                        type="radio"
                        name="DET_DAMAGE"
                        id="deterioration-no"
                        value={0}
                        defaultChecked={!sampleId || checkEmpty(sampleDetails.DET_DAMAGE) ? null : Number(sampleDetails.DET_DAMAGE) === 0 ? true : false}

                        onChange={handleChange}
                      />
                      <label htmlFor="deterioration-no">No</label>
                    </div>
                  </div>
                  {errors.DET_DAMAGE && (
                    <small className="error-message">{errors.DET_DAMAGE}</small>
                  )}
                </div>
              </div>
              <div className="col-4 card-body middle-card-body">
                <div className="card-title">Disturbance Factors</div>
                <div className="body-content">
                  <label htmlFor="" className="label">
                    Visible
                  </label>
                  <div className="radio-inputs">
                    <div className="radio-input">
                      <input
                        type="radio"
                        name="VISIBLE_DIST"
                        id="VISIBLE_DIST-yes"
                        value={1}

                        defaultChecked={!sampleId || checkEmpty(sampleDetails.VISIBLE_DIST) ? null : Number(sampleDetails.VISIBLE_DIST) === 1 ? true : false}

                        onChange={handleChange}
                      />
                      <label htmlFor="VISIBLE_DIST-yes">Yes</label>
                    </div>
                    <div className="radio-input">
                      <input
                        type="radio"
                        name="VISIBLE_DIST"
                        id="VISIBLE_DIST-no"
                        value={0}

                        defaultChecked={!sampleId || checkEmpty(sampleDetails.VISIBLE_DIST) ? null : Number(sampleDetails.VISIBLE_DIST) === 0 ? true : false}


                        onChange={handleChange}
                      />
                      <label htmlFor="VISIBLE_DIST-no">No</label>
                    </div>
                  </div>
                  {errors.VISIBLE_DIST && (
                    <small className="error-message">{errors.VISIBLE_DIST}</small>
                  )}
                  <label htmlFor="" className="label mt-3">
                    Accessible
                  </label>
                  <div className="radio-inputs">
                    <div className="radio-input">
                      <input
                        type="radio"
                        name="ACCESS_DIST"
                        id="ACCESS_DIST-yes"
                        value={1}

                        defaultChecked={!sampleId || checkEmpty(sampleDetails.ACCESS_DIST) ? null : Number(sampleDetails.ACCESS_DIST) === 1 ? true : false}
                        onChange={handleChange}
                      />
                      <label htmlFor="ACCESS_DIST-yes">Yes</label>
                    </div>
                    <div className="radio-input">
                      <input
                        type="radio"
                        name="ACCESS_DIST"
                        id="ACCESS_DIST-no"
                        value={0}

                        defaultChecked={!sampleId || checkEmpty(sampleDetails.ACCESS_DIST) ? null : Number(sampleDetails.ACCESS_DIST) === 0 ? true : false}
                        onChange={handleChange}
                      />
                      <label htmlFor="ACCESS_DIST-no">No</label>
                    </div>
                  </div>
                  {errors.ACCESS_DIST && (
                    <small className="error-message">{errors.ACCESS_DIST}</small>
                  )}
                  <label htmlFor="" className="label mt-3">
                    Friable
                  </label>
                  <div className="radio-inputs">
                    <div className="radio-input">
                      <input
                        type="radio"
                        name="FRIABLE"
                        id="FRIABLE-yes"
                        value={1}
                        defaultChecked={!sampleId || checkEmpty(sampleDetails.FRIABLE) ? null : Number(sampleDetails.FRIABLE) === 1 ? true : false}
                        onChange={handleChange}
                      />
                      <label htmlFor="FRIABLE-yes">Yes</label>
                    </div>
                    <div className="radio-input">
                      <input
                        type="radio"
                        name="FRIABLE"
                        id="FRIABLE-no"
                        value={0}
                        defaultChecked={!sampleId || checkEmpty(sampleDetails.FRIABLE) ? null : Number(sampleDetails.FRIABLE) === 0 ? true : false}
                        onChange={handleChange}
                      />
                      <label htmlFor="FRIABLE-no">No</label>
                    </div>
                  </div>
                  {errors.FRIABLE && (
                    <small className="error-message">{errors.FRIABLE}</small>
                  )}
                  <div className="mt-3 row">
                    <div className="col-6">
                      {" "}
                      <label className="label" htmlFor="">
                        AIR MOVEMENT
                      </label>
                      <Select
                        suffixIcon={
                          <i className="fi fi-rs-angle-small-down"></i>
                        }
                        // onChange={}
                        // options={[
                        //   { value: "Low", label: "Low" },
                        //   { value: "Mod", label: "Mod" },
                        //   { value: "High", label: "High" },
                        // ]}


                        options={air_move_activity?.map((item) => (
                          {
                            label: item?.description, value: (item?.description + "")
                          }
                        ))}
                        fieldNames={{ name: 'AIR_MOVE' }}
                        onChange={(e) => handleChange(e, null, "AIR_MOVE")} value={sampleDetails.AIR_MOVE}

                      />
                      {errors.AIR_MOVE && (
                        <small className="error-message">{errors.AIR_MOVE}</small>
                      )}
                    </div>
                    <div className="col-6">
                      {" "}
                      <label className="label" htmlFor="">
                        ACTIVITY
                      </label>
                      <Select
                        suffixIcon={
                          <i className="fi fi-rs-angle-small-down"></i>
                        }
                        // onChange={}
                        options={air_move_activity?.map((item) => (
                          {
                            label: item?.description, value: (item?.description + "")
                          }
                        ))}
                        fieldNames={{ name: "ACTIVITY" }}
                        onChange={(e) => handleChange(e, null, "ACTIVITY")}
                        value={sampleDetails.ACTIVITY}
                      />
                      {errors.ACTIVITY && (
                        <small className="error-message">
                          {errors.ACTIVITY}
                        </small>
                      )}
                    </div>
                  </div>
                  {/* <label htmlFor="" className="label mt-3">
                    Accessible
                  </label>
                  <div className="radio-inputs">
                    <div className="radio-input">
                      <input
                        type="radio"
                        name="demolished"
                        id="demolished-yes"
                        value={1}
                        // defaultChecked={!surveyId?null:sampleDetails.FULL_BLDG_SURVEY?true:false}
                        onChange={handleChange}
                      />
                      <label htmlFor="demolished-yes">Yes</label>
                    </div>
                    <div className="radio-input">
                      <input
                        type="radio"
                        name="demolished"
                        id="demolished-no"
                        value={0}
                        // defaultChecked={!surveyId?null:sampleDetails.FULL_BLDG_SURVEY?true:false}
                        onChange={handleChange}
                      />
                      <label htmlFor="demolished-no">No</label>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="col-4 card-body">
                <div className="card-title">Damage Detail</div>
                <div className="body-content">
                  <label className="label" htmlFor="">
                    DAMAGE POTENTIAL
                  </label>
                  <Select
                    suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                    // onChange={}
                    options=
                    // {[
                    //   { value: "0", label: "Low Damage Potential" },
                    //   { value: "1", label: "Potential Damage" },
                    //   { value: "2", label: "Potential for Sign. Damage" },
                    // ]}
                    {damage_potential?.map((item) => (
                      {
                        label: item?.description,
                        value: item?.description + ''
                      }
                    ))}
                    fieldNames={{ name: 'DAMAGE_POTENTIAL' }}
                    onChange={(e) => handleChange(e, null, "DAMAGE_POTENTIAL")} value={sampleDetails.DAMAGE_POTENTIAL}

                  />
                  {errors.DAMAGE_POTENTIAL && (
                    <small className="error-message">{errors.DAMAGE_POTENTIAL}</small>
                  )}
                  <label className="label mt-3" htmlFor="">
                    HAZARD ASSESMENT NO.
                  </label>
                  <Select
                    suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                    // onChange={}
                    options={
                      hazzard_assessment.map((hazard) => ({
                        value: hazard?.description + '',
                        label: hazard?.description
                      }))
                    }
                    fieldNames={{ name: 'HAZ_ASSES_CD' }}
                    onChange={(e) => handleChange(e, null, "HAZ_ASSES_CD")} value={sampleDetails.HAZ_ASSES_CD}
                  />
                  {errors.HAZ_ASSES_CD && (
                    <small className="error-message">{errors.HAZ_ASSES_CD}</small>
                  )}
                </div>
              </div>
            </div>
          </SampleCard>
          {sampleDetails?.ASB_TYPE == "PIPE" ? (
            <SampleCard header="Quantification">
              <div className="row">
                <div className="col-4">
                  <InputText
                    label="Pipe Diameter 0-2"
                    name="PIPE_DIA_0_2"
                    type='number'
                    onChange={handleChange}
                    value={sampleDetails?.PIPE_DIA_0_2} errors={errors.PIPE_DIA_0_2}
                  />
                </div>
                <div className="col-4">
                  <InputText
                    label="Pipe Diameter 3-6"
                    name="PIPE_DIA_3_6"
                    type='number'
                    onChange={handleChange}
                    value={sampleDetails?.PIPE_DIA_3_6} errors={errors.PIPE_DIA_3_6} />
                </div>
                <div className="col-4">
                  <InputText
                    label="Pipe Diameter 7-12"
                    name="PIPE_DIA_7_12"
                    type='number'
                    onChange={handleChange}
                    value={sampleDetails?.PIPE_DIA_7_12} errors={errors?.PIPE_DIA_7_12} />

                </div>
                <div className="col-4 mt-4">
                  <InputText
                    label="Pipe Diameter 12+"
                    name="PIPE_DIA_12_PLUS"
                    type='number'
                    onChange={handleChange}
                    value={sampleDetails?.PIPE_DIA_12_PLUS} errors={errors?.PIPE_DIA_12_PLUS}
                  />

                </div>
                <div className="col-4 mt-4">
                  <InputText
                    label="Pipe Linear Feet"
                    name="PIPE_LINEAR_FT"
                    onChange={handleChange}
                    value={sampleDetails?.PIPE_LINEAR_FT} errors={errors?.PIPE_LINEAR_FT}
                  />
                </div>
                <div className="col-4 mt-4">
                  <InputText
                    label="Fittings"
                    name="FITTINGS"
                    onChange={handleChange}
                    value={sampleDetails?.FITTINGS} errors={errors?.FITTINGS}
                  />
                </div>
                <div className="col-4 mt-4">
                  <InputText
                    label="#Elbows"
                    name="ELBOW"
                    type='number'
                    onChange={handleChange}
                    value={sampleDetails?.ELBOW} errors={errors?.ELBOW}
                  />
                </div>
                <div className="col-4 mt-4">
                  <InputText
                    label="Pipe Comments"
                    name="PIPE_COMMENTS"

                    onChange={handleChange}
                    value={sampleDetails?.PIPE_COMMENTS} errors={errors?.PIPE_COMMENTS}
                  />
                </div>
              </div>
            </SampleCard>
          ) : null}
          <div className="submit-btns">
            <button className="button primary-button" onClick={handleSubmit}>
              {!sampleId ? "Save New Sample" : "Update Sample"}
            </button>
            {sampleId ? (
              <button
                className="button secondary-button"
                onClick={handleDelete}
              >
                Delete Sample
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default SaveAsbestosSample;
