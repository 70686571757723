import { useEffect } from "react";
import "./App.scss";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import AppRoutes from "./routes";
import { warning } from "./services/toast";

function App() {
  const isMobileOrTablet = () => {
    return (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ) ||
      (window.innerWidth <= 800 && window.innerHeight <= 600)
    );
  };

  useEffect(() => {
    if (isMobileOrTablet()) {
      warning(
        "Please use this application on a PC/Laptop for the best experience."
      );
    }
  }, []);
  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
}

export default App;
