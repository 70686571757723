import axios from 'axios';
import { config } from '../config/config';
import { openNotificationWithIcon } from './toast';
import { useNavigate } from 'react-router-dom';
const navigate = useNavigate
const instance = axios.create({
  baseURL: config.API_BASE_URL,
  responseType: "json",
  timeout: 20000,
  timeoutErrorMessage: 'Server Timeout'
});

instance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = token
    }
    return config;
  },
  function (error) {
    console.log(error, "eooooo")
    return Promise.reject(error);
  }
);


instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {

    const { response } = error;
    if (response) {
      if (response?.data?.data?.disabled === true) {
        openNotificationWithIcon("error", "Your account is disabled. Kindly contact Admin.");
        return new Promise(() => { });
      }
      if (response?.data?.data?.deleted === true) {
        localStorage.clear()
        navigate('/')
        openNotificationWithIcon("error", "Your account is deleted. Kindly contact Admin.");

        return new Promise(() => { });

      }
      else if (response?.data?.data?.permission == false) {
        openNotificationWithIcon("error", "You are not allowed to perform this action.");
        return new Promise(() => { });
      }
      else {
        return Promise.reject(error);
      }
    } else {
      // Network error or other errors without a response
      openNotificationWithIcon("error", "Network error. Please check your connection.");
      return Promise.reject(error);
    }
  }
);
export default instance