import React from "react";
import { Route, Routes, Navigate, Router } from "react-router-dom";

import Login from "./pages/login";
import Users from "./pages/users";

// Asbestos routes

import AsbestosDashboard from "./pages/asbestos/dashboard";
import AsbestosBuildings from "./pages/asbestos/buildings";
import AsbestosCertifiedBuildings from "./pages/asbestos/buildings/certifiedBuildings";
import BuildingsWithAsbestos from "./pages/asbestos/buildings/buildingsWithAsbestos";
import BuildingWithoutAsbestos from "./pages/asbestos/buildings/buildingWithoutAsbestos";
import AsbestosSamples from "./pages/asbestos/samples";
import CreateAsbestosBuilding from "./pages/asbestos/buildings/Building.js";
import SaveAsbestosSample from "./pages/asbestos/samples/saveAsbestosSample.js";
import AsbestosSampleDetails from "./pages/asbestos/samples/view";
import AsbestosSurveys from "./pages/asbestos/surveys";
import SaveAsbestosSurvey from "./pages/asbestos/surveys/saveAsbestosSurvey.js";
import AsbestosResults from "./pages/asbestos/results";
import UpdateAsbestosResult from "./pages/asbestos/results/edit";
import AsbestosResultDetails from "./pages/asbestos/results/view";
import AsbestosTableOne from "./pages/asbestos/tables/table_one";
import AsbestosTableTwo from "./pages/asbestos/tables/table_two";
import AsbestosTableThree from "./pages/asbestos/tables/table_three";
import AsbestosAssessment from "./pages/asbestos/assessment";
import AsbestosLabReport from "./pages/asbestos/labReport";
import AsbestosAttachments from "./pages/asbestos/asbestosAttachments";
import AsbestosAdvfAttachments from "./pages/asbestos/advfAttachments";
import AsbestosRecAttachments from "./pages/asbestos/recAttachments";

// Lead routes

import LeadDashboard from "./pages/lead/dashboard";
import LeadBuildings from "./pages/lead/buildings";
import CreateLeadBuilding from "./pages/lead/buildings/create";
import LeadCertifiedBuildings from "./pages/lead/buildings/certifiedBuildings";
import BuildingsWithLead from "./pages/lead/buildings/buildingsWithLead";
import BuildingWithoutLead from "./pages/lead/buildings/buildingWithoutLead";
import LeadSamples from "./pages/lead/samples";
import CreateLeadSample from "./pages/lead/samples/create";
import LeadSampleDetails from "./pages/lead/samples/view";
import LeadSurveys from "./pages/lead/surveys";
import CreateLeadSurvey from "./pages/lead/surveys/create";
import LeadResults from "./pages/lead/results";
import UpdateLeadResult from "./pages/lead/results/edit";
import LeadResultDetails from "./pages/lead/results/view";
import LeadTableOne from "./pages/lead/tables/table_one";
import LeadTableTwo from "./pages/lead/tables/table_two";
import LeadTableThree from "./pages/lead/tables/table_three";
import LeadAssessment from "./pages/lead/assessment";
import LeadLabReport from "./pages/lead/labReport";
import LeadAttachments from "./pages/lead/leadAttachments";
import LeadAdvfAttachments from "./pages/lead/advfAttachments";
import LeadRecAttachments from "./pages/lead/recAttachments";
import ProtectedRoute from "./protectedRoutes/ProtectedRoute.js";
import Profile from "./pages/profile/index.js";
import CreateAssessment from "./pages/asbestos/assessment/saveAsbestosAssessment.js";
import { ErrorPage } from "./components/pages/Error.js";
import CreateResult from "./pages/asbestos/results/create.js";
import ViewAssessment from "./pages/asbestos/assessment/viewAssessment.js";

function AppRoutes() {
  return (
    <>
      <Routes>
        <Route strict path="/login" element={<Login />} />
      </Routes>

      <Routes>
        <Route path="/" element={<Navigate to={localStorage.getItem("token") ? "/asbestos" : "/login"} />} />
        <Route
          path="/asbestos"
          element={
            <ProtectedRoute>
              <AsbestosDashboard />
            </ProtectedRoute>
          }
        />
      </Routes>
      {/* <ProtectedRoute> */}
      <Routes>
        <Route strict path="/users" element={<Users />} />

        {/* Asbestos routes  */}

        {/* Buildings  */}

        <Route
          strict
          path="/asbestos/buildings"
          element={
            <ProtectedRoute>
              <AsbestosBuildings />
            </ProtectedRoute>
          }
        />
        <Route
          strict
          path="/asbestos/buildings/create"
          element={
            <ProtectedRoute>
              <CreateAsbestosBuilding />
            </ProtectedRoute>
          }
        />
        <Route
          strict
          path="/asbestos/buildings/update/:id"
          element={
            <ProtectedRoute>
              <CreateAsbestosBuilding />
            </ProtectedRoute>
          }
        />

        <Route
          strict
          path="/asbestos/certified-buildings"
          element={
            <ProtectedRoute>
              <AsbestosCertifiedBuildings />
            </ProtectedRoute>
          }
        />

        <Route
          strict
          path="/asbestos/buildings-with-asbestos"
          element={
            <ProtectedRoute>
              <BuildingsWithAsbestos />
            </ProtectedRoute>
          }
        />

        <Route
          strict
          path="/asbestos/buildings-without-asbestos"
          element={
            <ProtectedRoute>
              <BuildingWithoutAsbestos />
            </ProtectedRoute>
          }
        />

        {/* Samples  */}

        <Route
          strict
          path="/asbestos/samples"
          element={
            <ProtectedRoute>
              <AsbestosSamples />
            </ProtectedRoute>
          }
        />
        <Route
          strict
          path="/asbestos/samples/create"
          element={
            <ProtectedRoute>
              <SaveAsbestosSample />
            </ProtectedRoute>
          }
        />
        <Route
          strict
          path="/asbestos/samples/update/:id"
          element={
            <ProtectedRoute>
              <SaveAsbestosSample />
            </ProtectedRoute>
          }
        />
        <Route
          strict
          path="/asbestos/samples/view/:id"
          element={
            <ProtectedRoute>
              <AsbestosSampleDetails />
            </ProtectedRoute>
          }
        />

        {/* Surveys  */}

        <Route
          strict
          path="/asbestos/surveys"
          element={
            <ProtectedRoute>
              <AsbestosSurveys />
            </ProtectedRoute>
          }
        />
        <Route
          strict
          path="/asbestos/survey/create"
          element={
            <ProtectedRoute>
              <SaveAsbestosSurvey />
            </ProtectedRoute>
          }
        />
        <Route
          strict
          path="/asbestos/survey/update/:id"
          element={
            <ProtectedRoute>
              <SaveAsbestosSurvey />
            </ProtectedRoute>
          }
        />

        {/* Results  */}

        <Route
          strict
          path="/asbestos/results"
          element={
            <ProtectedRoute>
              <AsbestosResults />
            </ProtectedRoute>
          }
        />
        <Route
          strict
          path="/asbestos/results/view/:id"
          element={
            <ProtectedRoute>
              <AsbestosResultDetails />
            </ProtectedRoute>
          }
        />
        <Route
          strict
          path="/asbestos/results/edit/:id"
          element={
            <ProtectedRoute>
              <UpdateAsbestosResult />
            </ProtectedRoute>
          }
        />
        <Route
          strict
          path="/asbestos/results/create/:id"
          element={
            <ProtectedRoute>
              <CreateResult />
            </ProtectedRoute>
          }
        />

        {/* Tables  */}

        <Route
          strict
          path="/asbestos/table-one/:id"
          element={
            <ProtectedRoute>
              <AsbestosTableThree />
            </ProtectedRoute>
          }
        />
        <Route
          strict
          path="/asbestos/table-two/:id"
          element={
            <ProtectedRoute>
              <AsbestosTableThree />
            </ProtectedRoute>
          }
        />
        <Route
          strict
          path="/asbestos/table-three/:id"
          element={
            <ProtectedRoute>
              <AsbestosTableThree />
            </ProtectedRoute>
          }
        />

        {/* Assessment  */}

        <Route
          strict
          path="/asbestos/assessment"
          element={
            <ProtectedRoute>
              <AsbestosAssessment />
            </ProtectedRoute>
          }
        />
        <Route
          strict
          path="/asbestos/assessment/create"
          element={
            <ProtectedRoute>
              <CreateAssessment />
            </ProtectedRoute>
          }
        />
        <Route
          strict
          path="/asbestos/assessment/update/:id"
          element={
            <ProtectedRoute>
              <CreateAssessment />
            </ProtectedRoute>
          }
        />
        <Route
          strict
          path="/asbestos/assessment/view/:id"
          element={
            <ProtectedRoute>
              <ViewAssessment />
            </ProtectedRoute>
          }
        />

        {/* Lab Report  */}

        <Route
          strict
          path="/asbestos/lab-report/:id"
          element={
            <ProtectedRoute>
              <AsbestosTableThree />
            </ProtectedRoute>
          }
        />

        {/* Attachments  */}

        <Route
          strict
          // path="/asbestos/asbestos-attachments/:id"
          path="/asbestos/asbestos-attachments"
          element={
            <ProtectedRoute>
              <AsbestosAttachments />
            </ProtectedRoute>
          }
        />
        <Route
          strict
          path="/lead/asbestos-attachments"
          element={
            <ProtectedRoute>
              <AsbestosAttachments />
            </ProtectedRoute>
          }
        />

        {/* Lead routes  */}

        <Route
          strict
          path="/lead"
          element={
            <ProtectedRoute>
              {/* <LeadDashboard /> */}
              <AsbestosDashboard />
            </ProtectedRoute>
          }
        />

        {/* Buildings  */}

        <Route
          strict
          path="/lead/buildings"
          element={
            <ProtectedRoute>
              <AsbestosBuildings />
            </ProtectedRoute>
          }
        />
        <Route
          strict
          path="/lead/buildings/create"
          element={
            <ProtectedRoute>
              <CreateAsbestosBuilding />
            </ProtectedRoute>
          }
        />

        <Route
          strict
          path="/lead/buildings/update/:id"
          element={
            <ProtectedRoute>
              <CreateAsbestosBuilding />
            </ProtectedRoute>
          }
        />
        <Route
          strict
          path="/lead/certified-buildings"
          element={
            <ProtectedRoute>
              <AsbestosCertifiedBuildings />
            </ProtectedRoute>
          }
        />

        <Route
          strict
          path="/lead/buildings-with-lead"
          element={
            <ProtectedRoute>
              <BuildingsWithAsbestos />
            </ProtectedRoute>
          }
        />

        <Route
          strict
          path="/lead/buildings-without-lead"
          element={
            <ProtectedRoute>
              <BuildingWithoutAsbestos />
            </ProtectedRoute>
          }
        />

        {/* Samples  */}

        <Route
          strict
          path="/lead/samples"
          element={
            <ProtectedRoute>
              <AsbestosSamples />
            </ProtectedRoute>
          }
        />
        <Route
          strict
          path="/lead/samples/create"
          element={
            <ProtectedRoute>
              <SaveAsbestosSample />
            </ProtectedRoute>
          }
        />
        <Route
          strict
          path="/lead/samples/update/:id"
          element={
            <ProtectedRoute>
              <SaveAsbestosSample />
            </ProtectedRoute>
          }
        />
        <Route
          strict
          path="/lead/samples/view/:id"
          element={
            <ProtectedRoute>
              <AsbestosSampleDetails />
            </ProtectedRoute>
          }
        />

        {/* Surveys  */}

        <Route
          strict
          path="/lead/surveys"
          element={
            <ProtectedRoute>
              {/* <LeadSurveys /> */}
              <AsbestosSurveys />
            </ProtectedRoute>
          }
        />
        <Route
          strict
          path="/lead/survey/create"
          element={
            <ProtectedRoute>
              <SaveAsbestosSurvey />
            </ProtectedRoute>
          }
        />

        <Route
          strict
          path="/lead/survey/update/:id"
          element={
            <ProtectedRoute>
              <SaveAsbestosSurvey />
            </ProtectedRoute>
          }
        />
        {/* Results  */}

        <Route
          strict
          path="/lead/results"
          element={
            <ProtectedRoute>
              <AsbestosResults />
            </ProtectedRoute>
          }
        />
        <Route
          strict
          path="/lead/assessment/create"
          element={
            <ProtectedRoute>
              <CreateAssessment />
            </ProtectedRoute>
          }
        />
        <Route
          strict
          path="/asbestos/assessment/update/:id"
          element={
            <ProtectedRoute>
              <CreateAssessment />
            </ProtectedRoute>
          }
        />

        <Route
          strict
          path="/lead/results/create/:id"
          element={
            <ProtectedRoute>
              <CreateResult />
            </ProtectedRoute>
          }
        />
        <Route
          strict
          path="/lead/results/view/:id"
          element={
            <ProtectedRoute>
              <AsbestosResultDetails />
            </ProtectedRoute>
          }
        />
        <Route
          strict
          path="/lead/results/edit/:id"
          element={
            <ProtectedRoute>
              <UpdateAsbestosResult />
            </ProtectedRoute>
          }
        />
        <Route
          strict
          path="/lead/assessment/update/:id"
          element={
            <ProtectedRoute>
              <CreateAssessment />
            </ProtectedRoute>
          }
        />
        {/* Tables  */}

        <Route
          strict
          path="/lead/table-one/:id"
          element={
            <ProtectedRoute>
              <AsbestosTableThree />
            </ProtectedRoute>
          }
        />
        <Route
          strict
          path="/lead/table-two/:id"
          element={
            <ProtectedRoute>
              <AsbestosTableThree />
            </ProtectedRoute>
          }
        />
        <Route
          strict
          path="/lead/table-three/:id"
          element={
            <ProtectedRoute>
              <AsbestosTableThree />
            </ProtectedRoute>
          }
        />

        {/* Assessment  */}

        <Route
          strict
          path="/lead/assessment"
          element={
            <ProtectedRoute>
              <AsbestosAssessment />
            </ProtectedRoute>
          }
        />

        <Route
          strict
          path="/lead/assessment/view/:id"
          element={
            <ProtectedRoute>
              <ViewAssessment />
            </ProtectedRoute>
          }
        />
        {/* Lab Report  */}

        <Route
          strict
          path="/lead/lab-report/:id"
          element={
            <ProtectedRoute>
              <AsbestosTableThree />
            </ProtectedRoute>
          }
        />

        {/* Attachments  */}

        <Route
          strict
          path="/lead/lead-attachments/"
          element={
            <ProtectedRoute>
              <AsbestosAttachments />
            </ProtectedRoute>
          }
        />
        <Route
          strict
          path="/asbestos/lead-attachments/"
          element={
            <ProtectedRoute>
              <AsbestosAttachments />
            </ProtectedRoute>
          }
        />

        <Route
          strict
          path="/lead/mold-attachments/"
          element={
            <ProtectedRoute>
              <AsbestosAttachments />
            </ProtectedRoute>
          }
        />
        <Route
          strict
          path="/asbestos/mold-attachments/"
          element={
            <ProtectedRoute>
              <AsbestosAttachments />
            </ProtectedRoute>
          }
        />

        <Route
          strict
          path="/asbestos/advf-attachments/"
          element={
            <ProtectedRoute>
              <AsbestosAttachments />
            </ProtectedRoute>
          }
        />

        <Route
          strict
          path="/lead/advf-attachments/"
          element={
            <ProtectedRoute>
              <AsbestosAttachments />
            </ProtectedRoute>
          }
        />
        <Route
          strict
          path="/lead/rec-attachments/"
          element={
            <ProtectedRoute>
              <AsbestosAttachments />
            </ProtectedRoute>
          }
        />
        <Route
          strict
          path="/asbestos/rec-attachments/"
          element={
            <ProtectedRoute>
              <AsbestosAttachments />
            </ProtectedRoute>
          }
        />

        <Route
          strict
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        {/* <Route
        path="*"
        element={
          < ErrorPage />
        }
        /> */}

        {/* </ProtectedRoute> */}
      </Routes>
      <Routes>
        {/* <Route
          path="*"
          element={
            < ErrorPage />
          }
        /> */}
      </Routes>
    </>
  );
}

export default AppRoutes;
