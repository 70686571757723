import { config } from "../config/config";
import { openNotificationWithIcon } from "./toast";

export const asbestos_type_options = [
  "Amosite",
  "Chrysotile",
  "Crocidolite",
  "Other",
];
export const other_fibrous_options = [
  "Fiberglass",
  "Mineral Wool",
  "Cellulose",
  "Ceramic",
  "Synthetic Fibers",
  "other",
];
export const non_fibrous_options = [
  "Carbonaceous",
  "Non-Carbonaceous",
  "Ceramic",
  "Tile",
  "Tar",
  "Vinyl",
  "Filter, Binder",
  "Perlite",
  "Plaster",
  "Vermiculite",
  "Paint",
  "Clay",
  "Quartz",
  "Urethane",
  "Foam Glass",
  "Mastic",
  "Gypsum",
  "Ca Carbonate",
  "Glass",
  "Matrix",
  "Sealant",
  "Foam",
  "Brick",
  "Mortar",
  "Formica",
  "Foil",
  "Latex",
  "Other",
];

// export const COMMON_COLORS_OPTIONS = [
//   "Black",
//   "Brown",
//   "Clear",
//   "Gray",
//   "Silver",
//   "Tan",
//   "White",
//   "Yellow",
//   "Other",
// ]

export function getFirstPathSegment(path) {
  const normalizedPath = path.startsWith("/") ? path.slice(1) : path;

  const segments = normalizedPath.split("/");

  return segments[0];
}

export const buildingValidationObj = {
  BUILDING_ID: "BUILDING ID",
  DEMOLISHED: "DEMOLISHED",
  CERTIFIED: "CERTIFIED",
  NO_LEVELS: "LEVELS",
  BUILDING_SIZE: "BUILDING SIZE",
};

export const surveyValidationObj = {
  NO_LEVELS: "LEVELS",
  BUILDING_SIZE: "SIZE",
  DESCRIPTION: "DESCRIPTION",
  FULL_BLDG_SURVEY: "FULL BUILDING SURVEY",
  FIRE_PROTECTION: "FIRE PROTECTION",
  COMMENTS: "COMMENTS",
  STRUCTURE_TYPE: "STRUCTURE TYPE",
  EXTERIOR_TYPE: "EXTERIOR TYPE",
  ROOF_TYPE: "ROOF TYPE",
  HEATING_TYPE: "HEATING TYPE ",
  COOL_TYPE: "COOL TYPE",
  PLUMBING_TYPE: "PLUMBING TYPE",
  INACCESS_AREA: "INACCESS AREA",
  INACCESS_AREA_DESC: "INACCESS AREA DESC",
  INACCESS_AREA_ACM: "INACCESS AREA ACM",
  INACCESS_AREA_ACM_QTY: "INACCESS AREA ACM QTY",
  INACCESS_AREA_REASON: "INACCESS AREA REASON",
};

export const sampleValidationObj = {
  SAMPLE_ID: "SAMPLE NAME",
  SAMPLE_DATE: "SAMPLE DATE",
  LBTRY_ID: "LABORATORY",
  COMMENTS: "COMMENTS",
};

export const resultValidationObj = {
  ASB_PRESENT: "ASBESTOS PRESENT",
  LEAD_PRESENT: "LEAD PRESENT",
  HOMOG: "SAMPLE DATE",
  FPLA_ID: "FPLA ID ",
  RESULT_DATE: "RESULT DATE",
  TECH_ID: "TECH ID",
  LAYER_ID: "LAYER ID",
  CATEGORY: "TYPE",
  DIST_FEATURE: "MORPHOLOGY",
  DIST_FEATURE: "DIST FEATURE",
  RES_TYPE: "RESULT TYPE",
  ASB_MATERIAL: "ASBESTOS FOUND IN MATERIAL",
};

export const checkEmpty = function (item) {
  if (item === "" || item === undefined || item === null) {
    return true;
  } else {
    return false;
  }
};

// export const downloadFile = function (file, url, fileName) {
//   const token = localStorage.getItem("token");

//   fetch(`${config.API_BASE_URL}${url}`, {
//     method: "GET",
//     headers: {
//       Authorization: `Bearer ${token}`,
//       "Content-Type": "application/pdf",
//     },
//     responseType: "arraybuffer",
//   })
//     .then((res) => res.blob())
//     .then((blob) => {
//       const url = window.URL.createObjectURL(blob);
//       const link = document.createElement("a");
//       link.href = url;
//       link.download = `${fileName}.${file}`;
//       link.click();
//       window.URL.revokeObjectURL(url);
//     })
//     .catch((error) => {
//       console.error(`Error fetching PDF:`, error);
//     });
// };
export const downloadFile = async function (file, url, fileName) {
  const token = localStorage.getItem("token");

  try {
    const res = await fetch(`${config.API_BASE_URL}${url}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/pdf",
      },
    });

    if (!res.ok) {
      throw new Error("Failed to fetch file");
    }

    const blob = await res.blob();
    const objectUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = objectUrl;
    link.download = `${fileName}.${file}`;
    link.click();
    window.URL.revokeObjectURL(objectUrl);
  } catch (error) {
    console.error(`Error fetching file:`, error);
  }
};


export const isObjectEmpty = function (obj) {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
};

export const checkAccess = async (loginDetails, permission) => {
  const rights = await JSON.parse(loginDetails?.access_rights || "[]");
  // console.log(JSON.parse(loginDetails.access_rights),"rigghasa")
  if (
    (rights && rights.includes(permission)) ||
    loginDetails?.role === "admin" ||
    loginDetails?.designation == "SUBADMIN"
  ) {
    return true;
  } else {
    // const errorResponse = {
    //   statusCode: 403,
    //   error: 'Bad Request',
    //   message: 'You are not authorized to perform this action',
    //   data: { permission: false }
    // };
    return false;
    // openNotificationWithIcon("error", "You are not authorized to perform this action");
    // return h.response(errorResponse).code(400).takeover();
    // throw Config.APP_CONSTANTS.responseObject(403, 'You are not authorized to perform this action', { data: { permission: false } });
  }
};
