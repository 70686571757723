import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom/dist";
import { Link, NavLink } from "react-router-dom";
import Logo from "@/assets/images/logo-white.svg";
import LogoutIcon from "@/assets/images/logout-icon-white.svg";
import UserAvatar from "@/assets/images/user-avatar.png";
import { config } from "../../config/config";
import API from "../../services/ApiService";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentUser } from "../../redux/slice/userSlice";
import { ApiEndPoint } from "../../services/ApiEndpoint";
import { openNotificationWithIcon } from "../../services/toast";
import { closeBuildingResourceModal } from "../../redux/slice/buildingSlice";
import { checkAccess } from "../../services/Utils";

function Header() {
  const { currentUser } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };
  const goToProfile = () => {
    navigate("/profile");
  };

  const [userFirstName, setUserFirstName] = useState();
  const [accessChecked, setAccessChecked] = useState(false); // New state to handle access check status

  const [imageUrl, setImageUrl] = useState("");
  const [userDataFetched, setUserDataFetched] = useState(false);

  // async function check

  async function checkAccessLocal() {
    console.log(currentUser, "ccc")
    const updateAccess = await checkAccess(currentUser, "fsdg");
    console.log(updateAccess, "uppppp")
    setAccessChecked(updateAccess); // Set to true after check completes

  }

  useLayoutEffect(() => {

    checkAccessLocal();
  }, [currentUser])

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const user = userData ? JSON.parse(userData) : "";
    const { profile_picture } = userData ? JSON.parse(userData) : "";

    setImageUrl(profile_picture);
    setUserFirstName(user.first_name);
  }, []);

  useEffect(() => {
    if (!userDataFetched) {
      getProfileData();
      setUserDataFetched(true);
    }
  }, []);

  const getProfileApi = async () => {
    try {
      // setIsLoading(true);
      const response = await API.get(ApiEndPoint?.loginDetails);
      return response?.data?.data;
    } catch (error) {
      console.log(error, "------------------");
      openNotificationWithIcon("error", "error in fetching data");
    } finally {
      // setIsLoading(false);
    }
  };

  const getProfileData = async () => {
    try {
      const data = await getProfileApi();

      dispatch(updateCurrentUser(data));
    } catch (error) {
      console.log(error, "error");
    }
  };
  async function closeBuildingModal() {
    dispatch(closeBuildingResourceModal());
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg container-fluid">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={Logo} alt="" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav m-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  onClick={closeBuildingModal}
                  to="/asbestos"
                >
                  Asbestos
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  onClick={closeBuildingModal}
                  to="/lead"
                >
                  Lead
                </NavLink>
              </li>
              {accessChecked && (
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    onClick={closeBuildingModal}
                    to="/users"
                  >
                    Users
                  </NavLink>
                </li>
              )}
            </ul>
            <div className="d-flex">
              <button
                className="user-profile-button"
                type="submit"
                onClick={goToProfile}
              >
                <img
                  src={currentUser?.profile_picture || Logo}
                  alt="image"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = UserAvatar;
                  }}
                />
                <span>{currentUser?.user_name ?? "Robert"}</span>
              </button>
              <button
                className="logout-button"
                type="submit"
                onClick={handleLogout}
              >
                <div className="logout">
                  <img src={LogoutIcon} alt="" />
                </div>
                <span>Logout</span>
              </button>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
