import React, { useState } from "react";
import "./rec-attachments.scss";
import {
  GoBack,
  HeaderText,
  MainLayout,
  SearchInput,
  ExcelDownloadBtn,
  PdfDownloadBtn,
} from "@/components";

import { Modal, Select, DatePicker, Upload, message } from "antd";

function LeadRecAttachments() {
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const props = {
    name: "file",
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setModalOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };
  const onDateChange = (date) => {
    console.log(date);
  };

  return (
    <MainLayout>
      <div className="rec-attachments-wrapper">
        <HeaderText>
          <GoBack />
          REC Attachments · <span className="sample-title">FPK 1166</span>
        </HeaderText>
        <div className="table-wrapper">
          <div className="table-outer-header">
            <div className="header-filters">
              <SearchInput
                width={400}
                icon="start"
                placeholder="Search by file name, ID....."
                className="user-search-input"
              />
              <Select
                style={{ width: 170 }}
                placeholder="Material Condition"
                suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                // onChange={}
                options={[
                  { value: "jack", label: "Jack" },
                  { value: "lucy", label: "Lucy" },
                ]}
              />
            </div>
            <div className="header-buttons">
              <ExcelDownloadBtn />
              <PdfDownloadBtn />
              <button
                onClick={() => setModalOpen(true)}
                className="button yellow-button"
              >
                + Upload REC
              </button>
            </div>
          </div>
          <div className="table-overflow-wrapper container p-0">
            <table>
              <thead>
                <tr>
                  {[
                    "File NAME",
                    "ADDITIONAL_DATA",

                    "Date of report",
                    "date uploaded",
                    "Installation ID",
                  ].map((i) => (
                    <th key={i}>{i}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {[1, 2, 3, 4].map((i) => (
                  <tr key={i}>
                    <td>BLDG284_SURVEY_11MAR21.pdf</td>
                    <td>1</td>

                    <td>03/10/2021</td>
                    <td>05/06/2022</td>
                    <td>FPK</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <Modal
          width={480}
          className="attach-rec-modal"
          title=""
          centered
          open={modalOpen}
          onOk={handleOk}
          confirmLoading={confirmLoading}
          onCancel={() => setModalOpen(false)}
          okText="Upload REC File"
          footer={(_, { OkBtn }) => (
            <>
              <OkBtn />
            </>
          )}
        >
          <div className="attach-rec-file">
            <div className="title">
              <h3>Attach REC File</h3>
              <p className="">FPK 1166</p>
            </div>
            <div className="date-picker">
              <label htmlFor="" className="label">
                DATE OF REC REPORT
              </label>
              <DatePicker onChange={onDateChange} suffixIcon={false} />
            </div>
            <div className="upload-file">
              <label htmlFor="" className="label">
                UPLOAD FILE
              </label>
              <Upload {...props}>
                <button>Choose File</button>
              </Upload>
              <small className="warning-text">
                Please review the reports you are about to upload and attach.
              </small>
            </div>
          </div>
        </Modal>
      </div>
    </MainLayout>
  );
}

export default LeadRecAttachments;
