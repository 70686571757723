import { createSlice } from "@reduxjs/toolkit";
import { ApiEndPoint } from "../../services/ApiEndpoint";
import API from "../../services/ApiService";
import { openNotificationWithIcon } from "../../services/toast";

const initialState = {
  assessments: [],
  totalAssessments: 0,
  quesAns: {},
  sampleAreas: [],
  asb_onm_plan: [],
  assessmentDetails: {
    EXPOSURE_TOTAL: null,
    DAMAGE_TOTAL: null,
    13: null,
    12: null,
    11: null,
    10: null,
    9: null,
    8: null,
    7: null,
    6: null,
    5: null,
    4: null,
    3: null,
    2: null,
    1: null,
    AREA: [],
    DESCRIPTION: null,
    INSPECTION_DATE: "",
    // AREA: null,
  },
  currentPage: 1,
  isLoading: false,
  errors: {},
};

const assessmentSlice = createSlice({
  name: "assessments",
  initialState,
  reducers: {
    addAssessmentDetails(state, action) {
      const key = Object.keys(action.payload)[0];
      const value = action.payload[key];
      state.assessmentDetails[key] = value;
    },
    startLoading(state, action) {
      state.isLoading = true;
    },
    stopLoading(state, action) {
      state.isLoading = false;
    },
    getSingleAssementDetail(state, action) {
      state.assessmentDetails = action.payload;
    },
    changePage(state, action) {
      state.currentPage = action.payload;
      state.isLoading = false;
    },
    setSampleAreas(state, action) {
      state.sampleAreas = action.payload;
      state.isLoading = false;
    },
    getCommon(state, action) {
      console.log(action.payload, "---------------------");
      state[Object.keys(action.payload).toString()] = Object.values(
        action.payload
      ).flat();

      state.isLoading = false;
    },
    defaultAssessment(state, action) {
      state.assessmentDetails = {
        EXPOSURE_TOTAL: null,
        DAMAGE_TOTAL: null,
        13: null,
        12: null,
        11: null,
        10: null,
        9: null,
        8: null,
        7: null,
        6: null,
        5: null,
        4: null,
        3: null,
        2: null,
        1: null,
        DESCRIPTION: null,
        INSPECTION_DATE: "",
        // AREA: null,
        AREA: [],
      };
    },
    getAssessments(state, action) {
      if (state.currentPage == 1) {
        state.totalAssessments = action.payload.totalAssessments;
      }
      state.assessments = action.payload.assessments;
      state.isLoading = false;
    },
    addQuesAns(state, action) {
      state.quesAns = action.payload;
    },
    setErrors: {
      prepare(obj, msg) {
        return {
          payload: {
            obj,
            msg,
          },
        };
      },
      reducer(state, action) {
        if (action.payload.msg === "fullObj") {
          state.errors = action.payload.obj;
        } else {
          const key = Object.keys(action.payload.obj)[0];
          const value = action.payload.obj[key];
          state.errors[key] = value;
        }
      },
    },
  },
});

export const {
  changePage,
  addAssessmentDetails,
  setErrors,
  startLoading,
  defaultAssessment,
} = assessmentSlice.actions;

export function getQuesAnsData() {
  return async function (dispatch, getState) {
    dispatch({ type: "assessment/startLoading" });
    try {
      const response = await API.get(`${ApiEndPoint.assessmentQuesAns}`);
      const result = response?.data?.data;
      dispatch({ type: "assessments/addQuesAns", payload: result });
      // console.log(response,"response")
    } catch (error) {
      dispatch({ type: "assessments/stopLoading" });
      openNotificationWithIcon("error", error?.response?.data?.message);
    }
  };
}

export function getAssessments(id, search) {
  return async function (dispatch, getState) {
    dispatch({ type: "assessment/startLoading" });
    try {
      const assessmentState = getState();
      const currentPage = assessmentState.assessment.currentPage;
      const response = await API.get(
        `${ApiEndPoint.allAssessments}/${id}?skip=${(currentPage - 1) * 10}&limit=10${search ? `&search=${search}` : ""}`
      );
      const assessments = response?.data?.data?.listing;

      const totalAssessments = response?.data?.data?.count;
      dispatch({
        type: "assessments/getAssessments",
        payload: { assessments, totalAssessments },
      });
    } catch (error) {
      openNotificationWithIcon("error", error?.response?.data?.message);
    } finally {
      dispatch({ type: "assessments/stopLoading" });
    }
  };
}

export function addRemoveAssessmentOnm(checked, payload) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: "assessments/startLoading" });
      let response;
      if (checked) {
        response = await API.post(
          `${ApiEndPoint.addRemoveAssessmentOnm}1`,
          payload
        );
      } else {
        response = await API.post(
          `${ApiEndPoint.addRemoveAssessmentOnm}${payload.ASSESS_LIST_ID}`,
          payload
        );
      }

      console.log(response, "ressss");
    } catch (error) {
    } finally {
      dispatch({ type: "assessments/stopLoading" });
    }
  };
}
export function getAssessmentOnm(id) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: "assessments/startLoading" });

      let response = await API.get(`${ApiEndPoint.getAssessmentOnm}${id}`);
      return response?.data?.data;
    } catch (error) {
    } finally {
      dispatch({ type: "assessments/stopLoading" });
    }
  };
}

export function getSampleAreas(id) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: "assessments/startLoading" });
      // /v1/building/sample/area/{id}
      const response = await API.get(
        `${ApiEndPoint.sampleAreaByBuilding}${id}`
      );
      const data = response?.data?.data;

      dispatch({ type: "assessments/setSampleAreas", payload: data });
    } catch (error) {
      openNotificationWithIcon(
        "error",
        error?.response?.data?.message ?? "error occured"
      );
    } finally {
      dispatch({ type: "assessments/stopLoading" });
    }
  };
}

export function getAssessmentsRecord(id) {
  return async function (dispatch, getState) {
    dispatch({ type: "assessment/startLoading" });
    try {
      const endPoint = ApiEndPoint.assessmentsRecord.split("/");
      const suffix = endPoint.pop();
      const response = await API.get(`${endPoint.join("/")}/${id}/${suffix}`);
      const assessments = response?.data?.data?.listing;
      return assessments;
    } catch (error) {
      openNotificationWithIcon("error", error?.response?.data?.message);
    } finally {
      dispatch({ type: "assessments/stopLoading" });
    }
  };
}
export function getSingleAssementDetail(id) {
  return async function (dispatch, getState) {
    dispatch({ type: "assessments/startLoading" });
    try {
      const response = await API.get(`${ApiEndPoint.assessment}/${id}`);
      let data = response?.data?.data;
      console.log(data, "data");
      // data.AREA = JSON.parse(data.AREA);
      dispatch({
        type: "assessments/getSingleAssementDetail",
        payload: data,
      });
    } catch (error) {
      openNotificationWithIcon(
        "error",
        error?.response?.data?.message ?? "error occured"
      );
      console.log(error?.response?.data?.message);
    } finally {
      dispatch({ type: "assessments/stopLoading" });
    }
  };
}
export function deleteAssessment(id, buildingId) {
  return async function (dispatch, getState) {
    dispatch({ type: "assessments/startLoading" });
    try {
      const response = await API.delete(`${ApiEndPoint.assessment}/${id}`);
      dispatch({ type: "assessments/changePage", payload: 1 });
      dispatch(getAssessments(buildingId));
      openNotificationWithIcon("success", "Assessment deleted successfully");
    } catch (error) {
      openNotificationWithIcon("error", error?.response?.data?.message);
    } finally {
      dispatch({ type: "assessments/stopLoading" });
    }
  };
}
export function updateAssessment(id, changedData) {
  return async function (dispatch, getState) {
    dispatch({ type: "assessments/startLoading" });
    try {
      const newChangedData = JSON.parse(JSON.stringify(changedData));
      const finalData = { hazardousAssessments: {} };

      Object.entries(newChangedData).forEach(([key, value]) => {
        if (!isNaN(key)) {
          finalData.hazardousAssessments[key] = value;
        } else {
          finalData[key] = value;
        }
      });
      if (Object.keys(finalData.hazardousAssessments).length === 0) {
        delete finalData.hazardousAssessments;
      }
      finalData.AREA = JSON.stringify(finalData.AREA);

      const response = await API.put(
        `${ApiEndPoint.assessment}/${id}`,
        finalData
      );
      openNotificationWithIcon("success", "Assessment updated successfully");
      dispatch({ type: "assessments/defaultAssessment" });
    } catch (error) {
      console.log(error, "adad");
      openNotificationWithIcon("error", error?.response?.data?.message);
    } finally {
      dispatch({ type: "assessments/stopLoading" });
    }
  };
}

export function getCommon(term) {
  return async function (dispatch, getState) {
    dispatch({ type: "assessments/startLoading" });
    try {
      // const response = await API.get(ApiEndPoint[term]);
      const response = await API.get(
        `${ApiEndPoint.commonGet}?type=${term}&status=active`
      );
      dispatch({
        type: "assessments/getCommon",
        payload: { [term]: response?.data?.data },
      });
    } catch (error) {
      console.log(error, `${term} error`);
    }
  };
}
export function addAssessment() {
  return async function (dispatch, getState) {
    dispatch({ type: "assessments/startLoading" });
    try {
      const assessmentDetails = getState().assessment.assessmentDetails;
      const newAssessmentDetails = JSON.parse(
        JSON.stringify(assessmentDetails)
      );
      let extractedProperties = {};
      for (let i = 1; i <= 13; i++) {
        extractedProperties[i.toString()] = newAssessmentDetails[i.toString()];
        delete newAssessmentDetails[i.toString()];
      }
      // newAssessmentDetails.AREA = JSON.stringify(assessmentDetails.AREA);
      newAssessmentDetails.hazardousAssessments = extractedProperties;
      // if(newAssessmentDetails.id) delete newAssessmentDetails.id
      const response = await API.post(
        ApiEndPoint.assessment,
        newAssessmentDetails
      );

      openNotificationWithIcon("success", "Assessment created successfully");
      dispatch({ type: "assessments/defaultAssessment" });
    } catch (error) {
      openNotificationWithIcon("error", error?.response?.data?.message);
    } finally {
      dispatch({ type: "assessments/stopLoading" });
    }
  };
}

export default assessmentSlice.reducer;
