import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { AutoComplete, Select } from "antd";
import { Link, useLocation } from "react-router-dom";
import "./dashboard.scss";
import { MainLayout, Card, HeaderText } from "@/components";
import BuildingDarkIcon from "@/assets/images/building-black-icon.svg";
import BuildingDarkIconCertified from "@/assets/images/building-certified-icon.svg";
import WithoutAsbestos from "@/assets/images/without-asbestos.svg";
import WithAsbestos from "@/assets/images/with-asbestos.svg";
import API from "@/services/ApiService";
import { ApiEndPoint } from "@/services/ApiEndpoint";
import { useDispatch, useSelector } from "react-redux";
import {
  getBuildings,
  getBuildingsAsb,
  getBuildingsLead,
  getManagementPlan,
  openBuildingResourceModal,
  setBuildingsNull,
  upload_management,
} from "../../../redux/slice/buildingSlice";
import BuildingModal from "../../../components/modals/BuildingModal";
import DoughnutChart from "../../../services/CustomChart";
import InventoryModal from "../../../components/modals/InventoryModal";
import { config } from "../../../config/config";

function AsbestosDashboard() {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathName = location?.pathname?.substring(1);
  const presentKey = pathName === "lead" ? "LEAD_PRESENT" : "ASB_PRESENT";

  const category = pathName
    ? pathName.charAt(0).toUpperCase() + pathName.slice(1)
    : pathName;
  const { buildings } = useSelector((state) => state.building);
  const [buildingId, setBuildingId] = useState(null);
  const [buildingSelected, setBuildingSelected] = useState(false);
  const [buildingSelected2, setBuildingSelected2] = useState(false);
  const [buildingSelected3, setBuildingSelected3] = useState(false);
  const [buildingSelected4, setBuildingSelected4] = useState(false);
  // const [openInventoryModal, setInventoryModal] = useState(false)
  const [options, setOptions] = useState([]);

  const onSelect = (data) => {
    console.log(data,"sunny")
    const selectedBuilding = options.find((option) => option.value == data);
    const building_Id = selectedBuilding?.data;
    console.log(building_Id,"building_Id",data)
    setBuildingId(building_Id);
    setBuildingSelected(true);
  };
  const onSelect2 = (data) => {
    const selectedBuilding = options.find((option) => option.value == data);
    const building_Id = selectedBuilding?.data;
    setBuildingId(building_Id);
    setBuildingSelected2(true);
  };

  const onSelect3 = (data) => {
    const selectedBuilding = options.find((option) => option.value == data);
    const building_Id = selectedBuilding?.data;
    setBuildingId(building_Id);
    setBuildingSelected3(true);
  };
  const onSelect4 = (data) => {
    const selectedBuilding = options.find((option) => option.value == data);
    const building_Id = selectedBuilding?.data;
    setBuildingId(building_Id);
    setBuildingSelected4(true);
  };

  const getPanelValue = (searchText) => {
    if (searchText === "") {
      dispatch(setBuildingsNull());
    } else {
      setBuildingSelected(false);
      dispatch(getBuildings(pathName, "all", 0, searchText.trim()));
    }
  };

  const getPanelValue2 = (searchText) => {
    if (searchText === "") {
      dispatch(setBuildingsNull());
    } else {
      setBuildingSelected2(false);
      dispatch(getBuildings(pathName, "certified", 0, searchText.trim()));
    }
  };

  const getPanelValue3 = (searchText) => {
    if (searchText === "") {
      dispatch(setBuildingsNull());
    } else {
      setBuildingSelected3(false);
      if (pathName === "lead") {
        dispatch(getBuildingsLead(pathName, 0, searchText.trim()));
      } else {
        dispatch(getBuildingsAsb(pathName, 0, searchText.trim()));
      }
    }
  };
  const getPanelValue4 = (searchText) => {
    if (searchText === "") {
      dispatch(setBuildingsNull());
    } else {
      setBuildingSelected4(false);
      if (pathName === "lead") {
        dispatch(getBuildingsLead(pathName, 1, searchText.trim()));
      } else {
        dispatch(getBuildingsAsb(pathName, 1, searchText.trim()));
      }
    }
  };

  useEffect(() => {
    const data = buildings?.map((building) => ({
      value: building.BUILDING_NAME,
      data: building?.BUILDING_ID,
    }));
    setOptions(data);
  }, [buildings]);

  const [buildingsCount, setBuildingsCount] = useState({});

  const getDashboardData = async () => {
    try {
      const response = await API.get(
        `${ApiEndPoint.dashboadCount}/${pathName}`
      );
      setBuildingsCount((prevValue) =>
        prevValue?.total_buildings !== response?.data?.data?.total_buildings
          ? response?.data?.data
          : prevValue
      );
    } catch (error) {
      console.log(error, "errror");
    }
  };

  const selectedBuilding = () => {
    dispatch(openBuildingResourceModal());
  };

  useLayoutEffect(() => {
    getDashboardData();
  }, []);

  const chartData = useMemo(() => {
    return {
      labels: [`Buildings with ${category}`, `Buildings without ${category}`],
      values: [buildingsCount?.asb_present, buildingsCount?.asb_not_present],
      colors: ["#FF5049", "#508FF4"],
    };
  }, [buildingsCount]);

  function handleClear() {
    // console.log("hiiii")
    // (e)=>console.log(e,"hi")
  }

  const fileInputRef = useRef(null);

  const handleUpload = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("pdf", file);
      dispatch(upload_management(formData));
      console.log(`Uploading ${category} Management Plan:`, file);
    }
  };

  async function getPlan() {
    const data = await dispatch(getManagementPlan("asbestos"));
    console.log(config.AWS_URL/data?.fileName,"vicky")
    window.open(`${config.AWS_URL}${data?.fileName}`, "_blank").focus();
  console.log(2)
  }

  return (
    <>
      <MainLayout>
        <HeaderText>Dashboard</HeaderText>
        <div className="row dashboard-content">
          <div className="col-8">
            <div className="row">
              <div className="col-6">
                <Card bgColor="#F3F2FB" borderColor="#E5E3FD">
                  <div className="card-header">
                    <div className="card-header-text">
                      <h3>{buildingsCount?.total_buildings}</h3>
                      <p>All Buildings</p>
                    </div>
                    <div className="card-header-img">
                      <img src={BuildingDarkIcon} alt="" />
                    </div>
                  </div>
                  <div className="card-body">
                    {/* <Select
                      onChange={handleChange}
                      suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                      placeholder="Select By Name"
                      options={[
                        { value: "jack", label: "Jack" },
                        { value: "lucy", label: "Lucy" },
                      ]}
                    /> */}
                    <AutoComplete
                      options={options}
                      style={{
                        width: 350,
                      }}
                      onClear={handleClear}
                      onFocus={() => {
                        dispatch(getBuildings(pathName, "all", 0, "$$"));
                      }}
                      onSelect={onSelect}
                      onSearch={(text) => getPanelValue(text)}
                      placeholder="Search by name"
                    />
                    {!buildingSelected ? (
                      <Link
                        to={`/${pathName}/buildings`}
                        className="button primary-button"
                      >
                        All Buildings
                      </Link>
                    ) : (
                      <Link
                        onClick={selectedBuilding}
                        className="button primary-button"
                      >
                        Select Building
                      </Link>
                    )}
                    <Link
                      to={`/${pathName}/buildings?all`}
                      className="card-link"
                    >
                      All Buildings with Full Details
                    </Link>
                  </div>
                </Card>
              </div>
              <div className="col-6">
                <Card bgColor="#EAF6F7" borderColor="#CEF3F6">
                  <div className="card-header">
                    <div className="card-header-text">
                      <h3>{buildingsCount?.certified_buildings ?? 0}</h3>
                      <p>Certified Buildings</p>
                    </div>
                    <div className="card-header-img">
                      <img src={BuildingDarkIconCertified} alt="" />
                    </div>
                  </div>
                  <div className="card-body">
                    {/* <Select
                      onChange={handleChange}
                      placeholder="Select By Name"
                      suffixIcon={<i className="fi fi-rs-angle-small-down"></i>}
                      options={[
                        { value: "jack", label: "Jack" },
                        { value: "lucy", label: "Lucy" },
                      ]}
                    /> */}
                    <AutoComplete
                      options={options}
                      style={{
                        width: 350,
                      }}
                      onSelect={onSelect2}
                      onFocus={() => {
                        dispatch(getBuildings(pathName, "all", 0, "$$"));
                      }}
                      onSearch={(text) => getPanelValue2(text)}
                      placeholder="Search by name"
                    />
                    {!buildingSelected2 ? (
                      <Link
                        to={`/${pathName}/certified-buildings`}
                        className="button primary-button"
                      >
                        Certified Buildings
                      </Link>
                    ) : (
                      <Link
                        onClick={selectedBuilding}
                        className="button primary-button"
                      >
                        Select Building
                      </Link>
                    )}
                    <Link
                      to={`/${pathName}/certified-buildings?all`}
                      className="card-link"
                    >
                      Certified Buildings with Full Details
                    </Link>
                  </div>
                </Card>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-6">
                <Card bgColor="#FEF6E8" borderColor="#FBE7C5">
                  <div className="card-header">
                    <div className="card-header-text">
                      <h3>{buildingsCount?.asb_not_present ?? 0}</h3>
                      <p>Buildings without {category}</p>
                    </div>
                    <div className="card-header-img">
                      <img src={WithoutAsbestos} alt="" />
                    </div>
                  </div>
                  <div className="card-body">
                    <AutoComplete
                      options={options}
                      style={{
                        width: 350,
                      }}
                      onSelect={onSelect3}
                      onFocus={() => {
                        dispatch(getBuildingsAsb(pathName, 0, "$$"));
                      }}
                      onSearch={(text) => getPanelValue3(text)}
                      placeholder="Search by name"
                    />
                    {!buildingSelected3 ? (
                      <Link
                        to={`/${pathName}/buildings-without-${pathName}`}
                        className="button primary-button"
                      >
                        Buildings without {category}
                      </Link>
                    ) : (
                      <Link
                        onClick={selectedBuilding}
                        className="button primary-button"
                      >
                        Select Building
                      </Link>
                    )}

                    <Link
                      className="card-link"
                      to={`/${pathName}/buildings-without-${pathName}?all`}
                    >
                      Without {category} with Full Details
                    </Link>
                  </div>
                </Card>
              </div>
              <div className="col-6">
                <Card bgColor="#FFEDED" borderColor="#FED4D4">
                  <div className="card-header">
                    <div className="card-header-text">
                      <h3>{buildingsCount?.asb_present ?? 0}</h3>
                      <p>Buildings with {category}</p>
                    </div>
                    <div className="card-header-img">
                      <img src={WithAsbestos} alt="" />
                    </div>
                  </div>
                  <div className="card-body">
                    <AutoComplete
                      options={options}
                      style={{
                        width: 350,
                      }}
                      onSelect={onSelect4}
                      onFocus={() => {
                        dispatch(getBuildingsAsb(pathName, 1, "$$"));
                      }}
                      onSearch={(text) => getPanelValue4(text)}
                      placeholder="Search by name"
                    />
                    {!buildingSelected4 ? (
                      <Link
                        to={`/${pathName}/buildings-with-${pathName}`}
                        className="button primary-button"
                      >
                        Buildings with {category}
                      </Link>
                    ) : (
                      <Link
                        onClick={selectedBuilding}
                        className="button primary-button"
                      >
                        Select Building
                      </Link>
                    )}
                    {/* <Link
                      to="/asbestos/buildings-with-asbestos"
                      className="button primary-button"
                    >
                      Buildings with Asbestos
                    </Link> */}
                    <Link
                      className="card-link"
                      to={`/${pathName}/buildings-with-${pathName}?all`}
                    >
                      With {category} with Full Details
                    </Link>
                  </div>
                </Card>
              </div>
            </div>
          </div>
          <div className="col-4">
            <Card bgColor="rgba(255, 255, 255, 0.66)">
              <div className="dashboard-chart">
                {/* <svg
                  className="chart-layout"
                  width="328"
                  height="328"
                  viewBox="0 0 328 328"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_d_13_1153)">
                    <circle
                      cx="164"
                      cy="160"
                      r="120"
                      stroke="url(#paint0_linear_13_1153)"
                      strokeWidth="40"
                    />
                    <path
                      d="M164 40C230.274 40 284 93.7258 284 160C284 209.208 254.382 251.498 212 270.015"
                      stroke="url(#paint1_linear_13_1153)"
                      strokeWidth="40"
                      strokeLinecap="round"
                    />
                    <path
                      d="M164 40C192.576 40 218.819 49.9885 239.429 66.6646"
                      stroke="url(#paint2_linear_13_1153)"
                      strokeWidth="40"
                      strokeLinecap="round"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d_13_1153"
                      x="0"
                      y="0"
                      width="328"
                      height="328"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="12" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.22 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_13_1153"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_13_1153"
                        result="shape"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_13_1153"
                      x1="164"
                      y1="40"
                      x2="164"
                      y2="280"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#D69B04" />
                      <stop
                        offset="1"
                        stopColor="#D69B04"
                        stopOpacity="0.58"
                      />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_13_1153"
                      x1="224"
                      y1="263.163"
                      x2="248.243"
                      y2="74.3721"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#FF0000" />
                      <stop offset="1" stopColor="#FF9191" />
                    </linearGradient>
                    <linearGradient
                      id="paint2_linear_13_1153"
                      x1="226.346"
                      y1="79.9945"
                      x2="154.578"
                      y2="40"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3582D7" />
                      <stop offset="1" stopColor="#7AB2F1" />
                    </linearGradient>
                  </defs>
                </svg> */}
                <DoughnutChart data={chartData} />

                <span className="chart-text">
                  <h3>{buildingsCount?.total_buildings}</h3>
                  <p>All Buildings</p>
                </span>
              </div>
              <div className="chart-content">
                <ul>
                  {/* <li>
                    <div className="d-flex align-items-center">
                      <span className="line-color"></span>
                      <span className="line-text">Certified Buildings</span>
                    </div>
                    <div className="line-value">{buildingsCount?.certified_buildings}</div>
                  </li> */}
                  <li>
                    <div className="d-flex align-items-center">
                      <span className="line-color"></span>
                      <span className="line-text">
                        Buildings with {category}
                      </span>
                    </div>
                    <div className="line-value">
                      {buildingsCount?.asb_present}
                    </div>
                  </li>
                  <li>
                    <div className="d-flex align-items-center">
                      <span className="line-color"></span>
                      <span className="line-text">
                        Buildings without {category}
                      </span>
                    </div>
                    <div className="line-value">
                      {buildingsCount?.asb_not_present}
                    </div>
                  </li>
                </ul>
              </div>
            </Card>
            <div className="row mt-4">
              <div className="col-6">
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <button
                  className="button primary-outline-btn"
                  onClick={handleUpload}
                >
                  Upload {category} Management Plan
                </button>
              </div>
              <div className="col-6">
                <button
                  className="button primary-outline-btn"
                  onClick={getPlan}
                >
                  View {category} Management Plan
                </button>
              </div>
            </div>
          </div>
          <BuildingModal buildingId={buildingId} />
        </div>
      </MainLayout>
    </>
  );
}

export default AsbestosDashboard;
