import { createSlice } from "@reduxjs/toolkit";
import { ApiEndPoint } from "../../services/ApiEndpoint";
import API from "../../services/ApiService";
import { useDispatch } from "react-redux";
import { openNotificationWithIcon } from "../../services/toast";

const initialState = {
  buildings: [],
  buildingsRecord: [],
  totalBuildings: 0,
  totalArchievedBuildings: 0,
  pageSize:10,
  buildingDataCount: {},
  archievedBuildings: [],
  currentPage: 1,
  defaultBuildingId: null,
  isLoading: false,
  buildingResourcesModal: false,
  buildingDetails: {
    DESCRIPTION: "",
    NO_LEVELS: "",
    STATE: "",
    YEAR_CONST: "",
    BUILDING_ID: "",
    CERTIFIED: "",
    BUILDING_NAME: "",
    ADDRESS: "",
    ZIP: "",
    DEMOLISHED: "",
    STRUCTURE_NAME: "",
    BUILDING_SIZE: "",
    CITY: "",
    INSTLN_ID: "FJLC",
    LOCKED: "1",
    date_added: Date.now().toLocaleString(),
  },
  selectedBuilding: null,
  errors: {},
};

const buildingSlice = createSlice({
  name: "building",
  initialState,
  reducers: {
    getBuildings(state, action) {
      if (state.currentPage == 1) {
        state.totalBuildings = action.payload.totalBuildings;
        state.totalArchievedBuildings = action.payload.totalArchievedBuildings;
      }
      state.buildings = action.payload.buildings;
      state.isLoading = false;
    },
    getBuildingsRecord(state, action) {
      state.buildingsRecord = action.payload.buildings;
      state.isLoading = false;
    },
    getArchievedBuildings(state, action) {
      if (state.currentPage == 1) {
        state.totalArchievedBuildings = action.payload.totalArchievedBuildings;
      }
      state.buildings = action.payload.buildings;
      state.isLoading = false;
    },
    getBuildingDataCount(state, action) {
      state.buildingDataCount = action.payload;
    },
    getBuildingData(state, action) {
      state.buildingDetails = action.payload;
      state.isLoading = false;
    },
    setBuildingsNull(state, action) {
      state.buildings = null;
    },
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state, action) {
      state.isLoading = false;
    },
    changePage(state, action) {
      state.currentPage = action.payload.page
      state.pageSize= action.payload.pageSize
    },
    archiveBuilding(state, action) {},
    deleteBuilding(state, action) {},
    addBuildingDetails(state, action) {
      const key = Object.keys(action.payload)[0];
      const value = action.payload[key];
      state.buildingDetails[key] = value;
    },
    setBuildingId(state, action) {
      state.defaultBuildingId = action.payload;
    },
    defaultBuilding(state, action) {
      if (state?.buildingDetails?.id) delete state?.buildingDetails?.id;
      state.buildingDetails.DESCRIPTION = "";
      state.buildingDetails.NO_LEVELS = "";
      state.buildingDetails.STATE = "";
      state.buildingDetails.YEAR_CONST = "";
      state.buildingDetails.BUILDING_ID = "";
      state.buildingDetails.CERTIFIED = "";
      state.buildingDetails.BUILDING_NAME = "";
      state.buildingDetails.ADDRESS = "";
      state.buildingDetails.ZIP = "";
      state.buildingDetails.DEMOLISHED = "";
      state.buildingDetails.STRUCTURE_NAME = "";
      state.buildingDetails.BUILDING_SIZE = "";
      state.buildingDetails.CITY = "";
      state.buildingDetails.INSTLN_ID = "FJLC";
      state.buildingDetails.LOCKED = "1";
      state.buildingDetails.date_added = Date.now().toLocaleString();
    },
    openBuildingResourceModal(state, action) {
      state.isLoading = true;
      state.buildingResourcesModal = true;
      state.selectedBuilding = action.payload;
      state.isLoading = false;
    },
    closeBuildingResourceModal(state, action) {
      state.buildingResourcesModal = false;
    },
    setErrors: {
      prepare(obj, msg) {
        return {
          payload: {
            obj,
            msg,
          },
        };
      },
      reducer(state, action) {
        if (action.payload.msg === "fullObj") {
          state.errors = action.payload.obj;
        } else {
          const key = Object.keys(action.payload.obj)[0];
          const value = action.payload.obj[key];
          state.errors[key] = value;
        }
      },
    },
    addBuilding(state, action) {
      state.isLoading = false;
    },
  },
});

export const {
  changePage,
  setBuildingId,
  stopLoading,
  startLoading,
  addBuildingDetails,
  openBuildingResourceModal,
  closeBuildingResourceModal,
  setErrors,
  defaultBuilding,
  setBuildingsNull,
} = buildingSlice.actions;

export function archiveBuilding(category, id) {
  return async function (dispatch, getState) {
    dispatch({ type: "building/startLoading" });
    try {
      const response = await API.put(`${ApiEndPoint.archiveBuilding}/${id}`);
      openNotificationWithIcon("success", "Building archived successfully");
      // await dispatch(getBuildings("all", 1));
      await dispatch(getBuildings(category, "all", 0));
    } catch (error) {
      dispatch({ type: "building/stopLoading" });
      openNotificationWithIcon("error", error?.response?.data?.message);
    }
  };
}
export function unarchiveBuilding(category, id, isArchived) {
  return async function (dispatch, getState) {
    dispatch({ type: "building/startLoading" });
    try {
      const response = await API.put(`${ApiEndPoint.unarchiveBuilding}/${id}`);
      openNotificationWithIcon("success", "Building unarchived successfully");
      if (isArchived) {
        await dispatch(getBuildings(category, "all", 1));
      } else {
        await dispatch(getBuildings(category, "all", 0));
      }
    } catch (error) {
      openNotificationWithIcon("error", error?.response?.data?.message);
    } finally {
      dispatch({ type: "building/stopLoading" });
    }
  };
}

export function getBuildingData(id) {
  return async function (dispatch, getState) {
    dispatch({ type: "building/startLoading" });
    try {
      const response = await API.get(`${ApiEndPoint.building}/${id}`);
      console.log(response, "resp");
      const buildingData = response.data.data.data;
      console.log(buildingData, "buildingData");
      dispatch({ type: "building/getBuildingData", payload: buildingData });
    } catch (error) {
      dispatch({ type: "building/stopLoading" });
      openNotificationWithIcon("error", error?.response?.data?.message);
    }
  };
}

export function getBuildings(category='asbestos', type, archieved = 0, search) {
  return async function (dispatch, getState) {
    dispatch({ type: "building/startLoading" });
    try {
      const buildingState = getState();
      let limit = buildingState.building.pageSize || 10
      const currentPage = buildingState.building.currentPage;
      const response = await API.get(
        `${ApiEndPoint.building}?skip=${(currentPage - 1) * limit}&category=${category}&limit=${limit}${search ? `&search=${search}` : ""}${archieved ? `&archieved=${archieved}` : ""}&type=${type}`
      );
      const buildings = response.data.data.listing;
      const totalBuildings = response.data.data.count;
      const totalArchievedBuildings = response.data.data.totalArchievedCount;
      if (archieved) {
        dispatch({
          type: "building/getArchievedBuildings",
          payload: { buildings, totalBuildings, totalArchievedBuildings },
        });
      } else {
        dispatch({
          type: "building/getBuildings",
          payload: { buildings, totalBuildings, totalArchievedBuildings },
        });
      }
    } catch (error) {
      dispatch({ type: "building/stopLoading" });
      openNotificationWithIcon("error", "Failed to fetch Buildings");
    }
  };
}

export function getBuildingsAsb(category, type, search) {
  return async function (dispatch, getState) {
    dispatch({ type: "building/startLoading" });
    try {
      const buildingState = getState();
      let limit = buildingState.building.pageSize || 10

      const currentPage = buildingState.building.currentPage;
      const response = await API.get(
        `${ApiEndPoint.buildingsAsb}?skip=${(currentPage - 1) * limit}&limit=${limit}&category=${category}${search ? `&search=${search}` : ""}&ASB=${type}`
      );
      const buildings = response?.data?.data?.listing;
      const totalBuildings = response?.data?.data?.count;

      dispatch({
        type: "building/getBuildings",
        payload: { buildings, totalBuildings },
      });
    } catch (error) {
      dispatch({ type: "building/stopLoading" });
      openNotificationWithIcon("error", "Failed to fetch Buildings");
    }
  };
}

export function upload_management(attachment) {
  return async function (dispatch, getState) {
    dispatch({ type: "building/startLoading" });
    try {
      const response = await API.put(
        `${ApiEndPoint.management_plan}/asbestos`,
        attachment
      );
      openNotificationWithIcon(
        "success",
        "Management Plan Uploaded Successfully"
      );
      //   dispatch({ type: "attachment/addattachment" });
      // dispatch({type:"attachment/defaultattachment"})
      return response;
    } catch (error) {
      openNotificationWithIcon("error", error?.response?.data?.message);
    } finally {
      dispatch({ type: "building/stopLoading" });
    }
  };
}

export function getManagementPlan(plan) {
  return async function (dispatch, getState) {
    dispatch({ type: "building/startLoading" });
    try {
      const response = await API.get(
        `${ApiEndPoint.management_plan}/asbestos`,
        plan
      );
      // openNotificationWithIcon(
      //   "success",
      //   "Management Plan Uploaded Successfully"
      // );
      //   dispatch({ type: "attachment/addattachment" });
      // dispatch({type:"attachment/defaultattachment"})
      console.log(response?.data?.data[0]);
      return response?.data?.data[0];
    } catch (error) {
      openNotificationWithIcon("error", error?.response?.data?.message);
    } finally {
      dispatch({ type: "building/stopLoading" });
    }
  };
}

export function getBuildingsLead(category, type, search) {
  return async function (dispatch, getState) {
    dispatch({ type: "building/startLoading" });
    try {
      const buildingState = getState();
      const currentPage = buildingState.building.currentPage;
      let limit = buildingState.building.pageSize || 10

      const response = await API.get(
        `${ApiEndPoint.buildingsLead}?skip=${(currentPage - 1) * limit}&limit=${limit}&category=${category}${search ? `&search=${search}` : ""}&ASB=${type}`
      );
      const buildings = response?.data?.data?.listing;
      const totalBuildings = response?.data?.data?.count;

      dispatch({
        type: "building/getBuildings",
        payload: { buildings, totalBuildings },
      });
    } catch (error) {
      dispatch({ type: "building/stopLoading" });
      openNotificationWithIcon("error", "Failed to fetch Buildings");
    }
  };
}

export function getBuildingsRecord(type) {
  return async function (dispatch, getState) {
    dispatch({ type: "building/startLoading" });
    try {
      const response = await API.get(
        `${ApiEndPoint.buildingsRecord}?type=${type}`
      );
      const buildings = await response.data.data.listing;
      dispatch({ type: "building/getBuildingsRecord", payload: { buildings } });
      return buildings;
    } catch (error) {
      openNotificationWithIcon("error", "Failed to fetch Buildings");
    } finally {
      dispatch({ type: "building/stopLoading" });
    }
  };
}
export function getBuildingsAsbRecord(asb_present) {
  return async function (dispatch, getState) {
    dispatch({ type: "building/startLoading" });
    try {
      const response = await API.get(
        `${ApiEndPoint.buildingsAsbRecord}?ASB=${asb_present}`
      );
      const buildings = response.data.data.listing;
      dispatch({ type: "building/getBuildingsRecord", payload: { buildings } });
      return buildings;
    } catch (error) {
      openNotificationWithIcon("error", "Failed to fetch Buildings");
    } finally {
      dispatch({ type: "building/stopLoading" });
    }
  };
}
export function getBuildingsLeadRecord(lead_present) {
  return async function (dispatch, getState) {
    dispatch({ type: "building/startLoading" });
    try {
      const response = await API.get(
        `${ApiEndPoint.buildingsLeadRecord}?ASB=${lead_present}`
      );
      const buildings = response.data.data.listing;
      dispatch({ type: "building/getBuildingsRecord", payload: { buildings } });
      return buildings;
    } catch (error) {
      openNotificationWithIcon("error", "Failed to fetch Buildings");
    } finally {
      dispatch({ type: "building/stopLoading" });
    }
  };
}

export function getBuildingDataCount(category, building_Id) {
  return async function (dispatch, getState) {
    dispatch({ type: "building/startLoading" });
    try {
      const endPoint = ApiEndPoint.modalCount.split("/");
      const suffix = endPoint.pop();
      const data = await API.get(
        `${endPoint.join("/")}/${building_Id}/${suffix}?category=${category}`
      );
      console.log(data, "datata");
      const payloadData = data.data.data;
      dispatch({
        type: "building/getBuildingDataCount",
        payload: payloadData,
      });
    } catch (error) {
      dispatch({ type: "building/stopLoading" });

      console.log(error, "error");
      openNotificationWithIcon("error", "Unable to load modal data count");
    }
  };
}
export function deleteBuilding(category, id, isArchived) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: "building/startLoading" });
      const response = await API.delete(`${ApiEndPoint.building}/${id}`);
      if (isArchived) {
        await dispatch(getBuildings(category, "all", 1));
      } else {
        await dispatch(getBuildings(category, "all", 0));
      }
      openNotificationWithIcon("success", "Building deleted successfully");
    } catch (error) {
      dispatch({ type: "building/stopLoading" });
      openNotificationWithIcon("error", "Something went wrong");
    }
  };
}
export function addBuilding(pathName) {
  return async function (dispatch, getState) {
    dispatch({ type: "building/startLoading" });
    try {
      const buildingDetails = getState().building.buildingDetails;
      const newBuildingDetails = { ...buildingDetails };
      // if (newBuildingDetails.user_id) {
      //   delete newBuildingDetails.user_id;
      // }
      // if (newBuildingDetails.Has_full_survey) {
      //   delete newBuildingDetails.Has_full_survey;
      // }
      // if (newBuildingDetails.has_full_survey) {
      //   delete newBuildingDetails.has_full_survey;
      // }
      ['user_id', 'Has_full_survey', 'has_full_survey'].forEach(key => {
        if (key in newBuildingDetails) {
          delete newBuildingDetails[key];
        }
      });
      console.log(newBuildingDetails,"biiibl")
      newBuildingDetails.CATEGORY = pathName;
      const response = await API.post(ApiEndPoint.building, newBuildingDetails);
      openNotificationWithIcon("success", "Building created successfully");
      dispatch({ type: "building/addBuilding" });
      // dispatch({type:"building/defaultBuilding"})
    } catch (error) {
      dispatch({ type: "building/stopLoading" });
      openNotificationWithIcon("error", error?.response?.data?.message);
    }
  };
}

export function updateBuilding(buildingId, changedData) {
  return async function (dispatch, getState) {
    dispatch({ type: "building/startLoading" });
    try {
      const response = await API.put(
        `${ApiEndPoint.building}/${buildingId}`,
        changedData
      );

      openNotificationWithIcon("success", "Building has been updated");
      // dispatch({type:"building/defaultBuilding"})
    } catch (error) {
      console.log(".l.l");
      dispatch({ type: "building/stopLoading" });
      openNotificationWithIcon("error", error?.response?.data?.message);
      console.log(error?.response?.data?.message);
    }
  };
}

export default buildingSlice.reducer;
