// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";

// function ProtectedRoute({ children }) {
//   const navigate = useNavigate();
//   const [isLoggedIn, setIsLoggedIn] = useState(false);

//   const checkUserToken = () => {
//     const userToken = localStorage.getItem("token");
//     if (!userToken || userToken === "undefined") {
//       setIsLoggedIn(false);
//       return navigate("/login");
//     }
//     setIsLoggedIn(true);
//   };

//   useEffect(() => {
//     checkUserToken();
//   }, [isLoggedIn]);

//   return <div>{children}</div>;
// }

// export default ProtectedRoute;
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

 const ProtectedRoute = ({children}) => {
    const isAuthenticated =  localStorage.getItem("token");
    const navigate = useNavigate()

    useEffect(()=>{
        if(!isAuthenticated) navigate('/')
    },[isAuthenticated,navigate])
    return isAuthenticated? children:null
 }
export default ProtectedRoute
